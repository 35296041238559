<template>
  <div class="CoatingSelect">
    <!-- Main option select -->
    <SectionHeadline
      :text="`${ mixWB('IS_THERE_X_DIRECTLY_ON_Y', [mixWB('COATING'), typeName ]) }`">
      <template v-slot:icon>
        <BrushIcon />
      </template>
    </SectionHeadline>
    <div class="OptionList">
      <div
        v-show="activeStepNo >= 0"
        v-for="option in questionSchema.options"
        :key="option.id"
        class="Option"
        :class="{ 'IsSelected': option.id === savedData.mainOptionID }"
        @click="onMainOptionClick(option.id)">
        <span>{{ mixWB(option.translation) }}</span>
      </div>
    </div>

    <!-- Color select -->
    <ColorSelect
      v-show="activeStepNo >= 1 && showColorSelection"
      :selectedColorIDs="savedData.colorIDs"
      @color-update="onColorUpdate"  />

    <!-- Questions -->
    <QuestionsSelect
      v-show="activeStepNo >= 2 && mainOptionQuestions.length"
      :isActive="activeStepNo === 2"
      :questions="mainOptionQuestions"
      :resetQuestionsCounter="resetQuestionsCounter"
      subQuestionId="coating"
      @questions-updated="onQuestionsUpdated"
      @questions-all-answered="onQuestionsAllAnswered" />
  </div>
</template>

<script>
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import ColorSelect from '@/components/ScreeningTypeSections/ColorSelect.vue'
import QuestionsSelect from '@/components/ScreeningTypeSections/QuestionsSelect.vue'
import { mapGetters } from 'vuex'
import EventBus from '@/EventBus'
import { findSamplesAndTags } from '@/globals/javascript/_util/find-samples-and-tags'
import QuestionSelect from '@/globals/javascript/models/QuestionSelect'
import BrushIcon from '@/assets/svg/brush.svg?inline'

export default {
  name: 'CoatingSelect',
  props: {
    resetCoatingCounter: {
      type: Number,
      required: true,
    },
    groupName: {
      type: String,
      required: true,
    },
    typeName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      version: '2.0', // X.Y - Bump X when doing breaking changes. Bump Y when doing non-breaking changes.
      activeStepNo: 0,
      roadMap: [
        'main-options', // 0
        'color-select', // 1
        'questions', // 2
      ],
      resetQuestionsCounter: 0,
      savedData: {
        version: null,
        mainOptionID: null,
        colorIDs: [],
        answers: {},
        coatingTypeIDs: [],
        samples: {
          sampleIDs: [],
          items: [],
          manualSampleIDs: [],
        },
        tags: {
          tagIDs: [],
          items: [],
        },
        courseOfAction: 'demolition',
      },
      questions: [
        {
          id: 'Q-1',
          alwaysShow: true,
          areaBased: ['AREA-1'],
          position: 0,
        },
        {
          id: 'Q-13',
          alwaysShow: true,
          colorBased: ['CO-2'],
          position: 1,
        },
      ],
      questionSchema: {
        options: [
          {
            dkName: 'Ingen',
            id: 'COATING-OPTION-1',
            translation: 'NO',
          },
          {
            dkName: 'Maling',
            askForColor: true,
            coatingTypeIDs: ['COAT-2'],
            id: 'COATING-OPTION-2',
            sampleIDs: ['ST-2'],
            translation: 'PAINT',
          },
          {
            dkName: 'Kalk',
            askForColor: true,
            coatingTypeIDs: ['COAT-3'],
            id: 'COATING-OPTION-3',
            sampleIDs: ['ST-2'],
            translation: 'LIME',
          },
          {
            dkName: 'Maling og kalk',
            askForColor: true,
            coatingTypeIDs: ['COAT-2', 'COAT-3'],
            id: 'COATING-OPTION-4',
            sampleIDs: ['ST-2'],
            translation: 'PAINT_AND_LIME_2',
          },
          {
            dkName: 'Maling eller kalk',
            askForColor: true,
            coatingTypeIDs: ['COAT-4'],
            id: 'COATING-OPTION-5',
            sampleIDs: ['ST-2'],
            translation: 'PAINT_OR_LIME',
          },
          {
            dkName: 'Lak',
            askForColor: false,
            coatingTypeIDs: ['COAT-5'],
            id: 'COATING-OPTION-6',
            sampleIDs: ['ST-2'],
            translation: 'LACQUER',
          },
          {
            dkName: 'Fernis',
            askForColor: false,
            coatingTypeIDs: ['COAT-6'],
            id: 'COATING-OPTION-7',
            sampleIDs: ['ST-2'],
            translation: 'VARNISH',
          },
          {
            dkName: 'Lak eller fernis',
            askForColor: false,
            coatingTypeIDs: ['COAT-7'],
            id: 'COATING-OPTION-8',
            sampleIDs: ['ST-2'],
            translation: 'LACQUER_OR_VARNISH',
          },
          {
            dkName: 'Maling og lak',
            askForColor: true,
            coatingTypeIDs: ['COAT-2', 'COAT-5'],
            id: 'COATING-OPTION-9',
            sampleIDs: ['ST-2'],
            translation: 'PAINT_AND_LACQUER',
          },
          {
            dkName: 'Maling og fernis',
            askForColor: true,
            coatingTypeIDs: ['COAT-2', 'COAT-6'],
            id: 'COATING-OPTION-10',
            sampleIDs: ['ST-2'],
            translation: 'PAINT_AND_VARNISH',
          },
          {
            dkName: 'Maling og lak eller fernis',
            askForColor: true,
            coatingTypeIDs: ['COAT-2', 'COAT-7'],
            id: 'COATING-OPTION-11',
            sampleIDs: ['ST-2'],
            translation: 'PAINT_AND_LACQUER_OR_VARNISH',
          },
          {
            dkName: 'Støvbinder',
            askForColor: false,
            coatingTypeIDs: ['COAT-8'],
            id: 'COATING-OPTION-12',
            sampleIDs: ['ST-2'],
            translation: 'DUST_BINDER',
          },
          {
            dkName: 'Lim',
            askForColor: false,
            coatingTypeIDs: ['COAT-9'],
            id: 'COATING-OPTION-13',
            sampleIDs: ['ST-2'],
            translation: 'GLUE',
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters([
      'currentCategory',
      'questionsAsObject',
    ]),
    selectedMainOption() {
      return this.questionSchema.options.find((x) => x.id === this.savedData.mainOptionID)
    },
    showColorSelection() {
      if (!this.selectedMainOption) {
        return false
      }

      if (this.selectedMainOption.askForColor) {
        return true
      }

      return false
    },
    mainOptionQuestions() {
      if (!this.selectedMainOption) {
        return []
      }

      if (this.selectedMainOption.id === 'COATING-OPTION-1') {
        return []
      }

      return this.questions.reduce((prev, coatingQuestion) => {
        let addQuestion = true

        // Check to add color question
        if (coatingQuestion.colorBased) {
          let allGood = false
          coatingQuestion.colorBased.forEach((colorID) => {
            if (allGood) {
              return
            }
            allGood = !!this.savedData.colorIDs.includes(colorID)
          })
          if (!allGood) {
            addQuestion = false
          }
        }

        // Check to add coating question based on area
        if (addQuestion && coatingQuestion.areaBased) {
          addQuestion = !!coatingQuestion.areaBased.includes(this.currentCategory.areaID)
        }

        // Add questions
        if (addQuestion) {
          const question = this.questionsAsObject[coatingQuestion.id]
          prev.push(new QuestionSelect({
            id: question.id,
            title: question.getTitle(),
            sampleIDs: [...question.sampleIDs],
            tagIDs: question.getTagsIDs(),
            assessmentIDs: [...question.assessmentIDs],
            alwaysShow: coatingQuestion.alwaysShow,
          }))
        }

        return prev
      }, [])
    },
  },
  watch: {
    resetCoatingCounter() {
      this.onResetCoating()
    },
  },
  methods: {
    onMainOptionClick(optionID) {
      if (this.savedData.mainOptionID === optionID) {
        return
      }

      // Reset
      this.onResetCoating()
      this.resetQuestionsCounter += 1

      this.activeStepNo = 0
      this.savedData.mainOptionID = optionID

      this.onNextStep()
    },
    onColorUpdate(colorIDs) {
      this.savedData.colorIDs = colorIDs

      this.activeStepNo = 1

      // Reset
      this.savedData.coatingTypeIDs = []
      this.savedData.samples.sampleIDs = []
      this.savedData.samples.items = []
      this.savedData.tags.tagIDs = []
      this.savedData.tags.items = []
      this.savedData.answers = {}
      this.resetQuestionsCounter += 1

      if (!colorIDs.length) {
        this.$emit('coating-updated')
        return
      }

      this.onNextStep()
    },
    onQuestionsUpdated() {
      this.$emit('coating-updated')
    },
    onQuestionsAllAnswered(answers) {
      this.$set(this.savedData, 'answers', answers)
      this.onNextStep()
    },
    onNextStep() {
      this.$emit('coating-updated')

      if (this.activeStepNo === 0) {
        // Color select
        if (this.showColorSelection) {
          this.activeStepNo += 1
          return
        }

        // Questions
        if (this.mainOptionQuestions.length) {
          this.activeStepNo += 2
          return
        }
      }

      if (this.activeStepNo === 1) {
        // Questions
        if (this.mainOptionQuestions.length) {
          this.activeStepNo += 1
          return
        }
      }

      if (this.activeStepNo === 2) {
        // Do nothing
      }

      this.saveCoatingAndSamples()

      // Set version if missing
      if (!this.savedData.version) {
        this.savedData.version = this.version
      }

      this.$emit('coating-finished', this.savedData)
    },
    saveCoatingAndSamples() {
      const {
        samples,
        tags,
      } = findSamplesAndTags({
        selectedOption: this.selectedMainOption,
        answers: this.savedData.answers,
        questions: this.mainOptionQuestions,
      })

      this.savedData.coatingTypeIDs = []
      this.savedData.samples.sampleIDs = samples.sampleIDs
      this.savedData.samples.items = samples.items
      this.savedData.tags = tags

      if (this.selectedMainOption.id === 'COATING-OPTION-1') {
        return
      }

      // Set coating types from selected option
      if (this.selectedMainOption.coatingTypeIDs.length) {
        this.savedData.coatingTypeIDs = this.selectedMainOption.coatingTypeIDs
      }
    },
    onTypeCoatingLoad({ coating, groupName }) {
      if (this.groupName !== groupName) {
        return
      }

      this.$set(this, 'savedData', coating)
      this.activeStepNo = this.roadMap.length - 1
      EventBus.$emit('load-sub-question', { id: 'coating', ...this.savedData })
    },
    onResetCoating() {
      this.savedData = {
        version: null,
        mainOptionID: null,
        colorIDs: [],
        answers: {},
        coatingTypeIDs: [],
        samples: {
          sampleIDs: [],
          items: [],
          manualSampleIDs: [],
        },
        tags: {
          tagIDs: [],
          items: [],
        },
        courseOfAction: 'demolition',
      }
    },
  },
  components: {
    SectionHeadline,
    ColorSelect,
    QuestionsSelect,
    BrushIcon,
  },
  created() {
    EventBus.$on('load-type-coating', this.onTypeCoatingLoad)
  },
  destroyed() {
    EventBus.$off('load-type-coating', this.onTypeCoatingLoad)
  },
}
</script>

<style lang="stylus" scoped>
  .CoatingSelect
    padding 20px 0px 5px
    span
      display block
    +below($wide)
      border-top 1px solid $color_grey_lighter
      background-color $color_grey_lightest
      padding 20px 20px 5px
      margin 0 -20px 0px
    +below($tablet)
      padding 20px 15px 5px
      margin 0 -15px 0px

  .OptionList
    display flex
    flex-wrap wrap
    margin 0px -5px 10px

  .Option
    display flex
    align-items center
    justify-content center
    min-width 70px
    min-height 40px
    padding 5px 10px
    margin 0px 5px 10px
    background-color $color_grey_lightest
    border 1px solid lighten($color_grey_lighter, 25%)
    box-shadow $box_shadow_1
    cursor pointer
    span
      display block
      text-align center
      text-transform uppercase
      font-size 0.875rem
    &.IsSelected
      background-color $color_primary
      border-color $color_primary
      box-shadow $box_shadow_1
      span
        color #fff
</style>
