<template>
  <div class="InterConnectionsSelect">
    <SectionHeadline :text="mixWB('SITTING_TOGETHER_WITH_INSIDE_AND_OUT')" />

    <!-- Current inter connection groups -->
    <InterConnectionRow
      v-for="group in interConnectionGroups"
      :key="group.id"
      :interConnectionGroupID="group.groupID"
      :typeItems="group.typeItems"
      :isAmountRequired="group.options.isAmountRequired"
      :typeData="typeData"
      :autoTypes="autoTypes"
      :allInterConnections="allInterConnections"
      :allScreeningTypes="allScreeningTypes"
      :allInterConnectionGroupAmounts="allInterConnectionGroupAmounts"
      @area-update="onAreaUpdate"
      @no-type-selected="onNoTypeSelected"
      @inter-connection-select="onInterConnectionSelect" />

    <!-- New inter connection group row -->
    <InterConnectionRow
      v-if="!activeAutoTypes.length"
      :typeData="typeData"
      :autoTypes="autoTypes"
      :allInterConnections="allInterConnections"
      :allScreeningTypes="allScreeningTypes"
      :allInterConnectionGroupAmounts="allInterConnectionGroupAmounts"
      @inter-connection-select="onInterConnectionSelect" />

    <!-- Ask if type is own type as well -->
    <div v-if="showExistsOnItsOwn">
      <Checkbox
        name="exists-on-its-own"
        :text="mixWB(
          'IS_X_ALSO_FOUND_AS_ITS_OWN_TYPE_TEXT',
          [typeData.getTitle(true)]
        )"
        :isSelected="!!typeData.isOwnTypeAsWell"
        @checkbox-click="onCheckBoxClick" />
    </div>

    <!-- Dialogs -->
    <Dialog
      :isShowing="nextQuestion.showQuestion || false"
      :useComponent="QuestionModal"
      :easyClose="false"
      :componentProps="{
        title: nextQuestion.questionTitle,
        question: nextQuestion.question || '',
        questionID: nextQuestion.id,
        additionalData: nextQuestion.additionalData,
        isYesOutlined: true,
      }"
      @answer="onQuestionAnswer"
      @close="onQuestionClose" />

    <Dialog
      :isShowing="showNotificationModal"
      :useComponent="NoficationModal"
      :easyClose="false"
      :componentProps="{
        texts: notificationTexts,
      }"
      @close="onNotificationClose" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DB_INTER_CONNECTIONS, setIDOnCreate } from '@/globals/javascript/models/_helper'
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import InterConnectionRow from '@/components/ScreeningTypeSections/InterConnections/InterConnectionRow.vue'
import EventBus from '@/EventBus'
import InterConnection from '@/globals/javascript/models/InterConnection'
import Checkbox from '@/components/FormElements/Checkbox.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import QuestionModal from '@/components/Modals/QuestionModal.vue'
import NoficationModal from '@/components/Modals/NoficationModal.vue'
import { union } from 'lodash-es'
import Type from '@/globals/javascript/models/Type'
import { getRawGroupedTypes } from '@/globals/javascript/_util/grouped-types'

export default {
  name: 'InterConnectionsSelect',
  props: {
    typeData: {
      type: Object,
      required: true,
    },
    autoTypes: {
      type: Array,
      required: true,
    },
    resourceType: {
      type: [Object, Boolean],
      required: true,
    },
  },
  data() {
    return {
      addedInterConnections: [],
      removedInterConnectionIDs: [],
      addedInterConnectionGroupAmounts: [],
      removedInterConnectionGroupAmounts: [],
      typesExistsOnTheirOwnAnswers: {},
      existingInterConnectionGroupsToCopy: [],

      // Question modal props
      QuestionModal,
      questions: [
        {
          id: 'type-exists-on-its-own',
          questionTitle: this.mixWB('IS_OWN_TYPE'),
          question: '',
          showQuestion: false,
          additionalData: {
            questionTypeID: '',
          },
        },
        {
          id: 'group-exists-on-its-own',
          questionTitle: this.mixWB('NEW_INTERCONNECTION'),
          question: '',
          showQuestion: false,
          isQuestionAwaitingResolve: false,
          unresolvedAnswer: null,
        },
      ],
      // Notificatin modal
      showNotificationModal: false,
      NoficationModal,
      notificationTexts: [],
    }
  },
  computed: {
    ...mapGetters([
      'screeningTypes',
      'screeningInterConnections',
      'currentScreeningData',
    ]),
    showExistsOnItsOwn() {
      return this.interConnectionGroups.length
        && !this.resourceType?.options?.isNeverAlone
    },
    activeAutoTypes() {
      return this.autoTypes.filter((x) => x.answer === 'yes')
    },
    nextQuestion() {
      return this.questions.find((x) => x.showQuestion) || {}
    },
    allInterConnectionGroupAmounts() {
      const combined = [...this.addedInterConnectionGroupAmounts]
      this.currentScreeningData.interConnectionGroupAmounts.forEach((groupAmount) => {
        const alreadyAdded = combined.find((x) => x.id === groupAmount.id)
        if (!alreadyAdded) {
          combined.push(groupAmount)
        }
      })

      return combined.filter((x) => !this.removedInterConnectionGroupAmounts.includes(x.id))
    },
    allInterConnections() {
      const combined = [...this.addedInterConnections]
      this.screeningInterConnections.forEach((interConnection) => {
        const alreadyAdded = combined.find((x) => x.id === interConnection.id)
        if (!alreadyAdded) {
          combined.push(interConnection)
        }
      })

      return combined.filter((x) => !this.removedInterConnectionIDs.includes(x.id))
    },
    allScreeningTypes() {
      const usedIDs = []
      const combined = []

      // Add main type
      combined.push(this.typeData)
      usedIDs.push(this.typeData.id)

      // Add auto types
      this.activeAutoTypes.forEach((autoType) => {
        combined.push(autoType.type)
        usedIDs.push(autoType.type.id)
      })

      // Add rest
      this.screeningTypes.forEach((type) => {
        if (usedIDs.includes(type.id)) {
          return
        }
        combined.push(type)
      })

      return combined
    },
    interConnectionGroups() {
      const groupedTypes = getRawGroupedTypes({
        interConnections: this.allInterConnections,
        screeningTypes: this.allScreeningTypes,
      })

      return groupedTypes.filter((x) => x.options.isGroup && x.typeIDs.includes(this.typeData.id))
    },
    missingInterConnectionGroupAmountIDs() {
      const groups = this.interConnectionGroups.filter((group) => {
        if (!group.options.isAmountRequired) {
          return false
        }

        const groupAmount = this.allInterConnectionGroupAmounts.find(
          (x) => x.id === group.groupID,
        )

        return !groupAmount?.m2
      })

      return groups.map((x) => x.groupID)
    },
  },
  watch: {
    activeAutoTypes(to) {
      // Remove all previous connections
      this.removedInterConnectionIDs = []
      this.addedInterConnections = []

      // Add any new inter connections
      const newGroupID = setIDOnCreate(null, DB_INTER_CONNECTIONS)
      let lastType = this.typeData
      to.forEach((autoType) => {
        if (autoType.answer === 'no') {
          return
        }

        const newInterConnection = new InterConnection({
          groupID: newGroupID,
          innerTypeID: autoType.side === 'inner' ? autoType.type.id : lastType.id,
          outerTypeID: autoType.side === 'inner' ? lastType.id : autoType.type.id,
        })

        this.addedInterConnections.push(newInterConnection)

        // Check to specify split status
        this.checkToAutoSetSplitStatus({
          interConnection: newInterConnection,
        })
        lastType = autoType.type
      })
    },
    addedInterConnections() {
      this.$emit('data-update', {
        name: 'addedInterConnections',
        value: this.addedInterConnections,
      })
    },
    removedInterConnectionIDs() {
      this.$emit('data-update', {
        name: 'removedInterConnectionIDs',
        value: this.removedInterConnectionIDs,
      })

      // Update added connections
      this.addedInterConnections = this.addedInterConnections.filter(
        (x) => !this.removedInterConnectionIDs.includes(x.id),
      )
    },
    addedInterConnectionGroupAmounts() {
      this.$emit('data-update', {
        name: 'addedInterConnectionGroupAmounts',
        value: this.addedInterConnectionGroupAmounts,
      })
    },
    removedInterConnectionGroupAmounts() {
      this.$emit('data-update', {
        name: 'removedInterConnectionGroupAmounts',
        value: this.removedInterConnectionGroupAmounts,
      })

      // Update added connections
      this.addedInterConnectionGroupAmounts = this.addedInterConnectionGroupAmounts.filter(
        (x) => !this.removedInterConnectionGroupAmounts.includes(x.id),
      )
    },
    typesExistsOnTheirOwnAnswers() {
      this.$emit('data-update', {
        name: 'typesExistsOnTheirOwnAnswers',
        value: this.typesExistsOnTheirOwnAnswers,
      })
    },
    existingInterConnectionGroupsToCopy() {
      this.$emit('data-update', {
        name: 'existingInterConnectionGroupsToCopy',
        value: this.existingInterConnectionGroupsToCopy,
      })
    },
    missingInterConnectionGroupAmountIDs() {
      this.$emit('data-update', {
        name: 'missingInterConnectionGroupAmountIDs',
        value: this.missingInterConnectionGroupAmountIDs,
      })
    },
  },
  methods: {
    getConnectionValidStatus({
      rowInterConnectionGroupID,
      interConnectionGroupID,
      typeID,
      side,
    }) {
      let foundTypeIDs = []
      let foundDuplicateTypeIDs = []
      // Find type ids for existing row
      if (rowInterConnectionGroupID) {
        const interConnections = InterConnection.getGroupInterConnectionsFromTypeID({
          typeID: this.typeData.id,
          interConnectionGroupID: rowInterConnectionGroupID,
          side: side === 'inner' ? 'outer' : 'inner',
          interConnections: this.allInterConnections,
        })
        interConnections.forEach((interConnection) => {
          if (interConnection.innerTypeID) {
            foundTypeIDs = union(foundTypeIDs, [interConnection.innerTypeID])
          }
          if (interConnection.outerTypeID) {
            foundTypeIDs = union(foundTypeIDs, [interConnection.outerTypeID])
          }
        })
      }

      // Find type ids for selected inter connection group
      if (interConnectionGroupID) {
        const interConnections = this.allInterConnections.filter(
          (x) => x.groupID === interConnectionGroupID,
        )
        let foundGroupTypeIDs = []
        interConnections.forEach((interConnection) => {
          if (interConnection.innerTypeID) {
            foundGroupTypeIDs = union(foundGroupTypeIDs, [interConnection.innerTypeID])
          }
          if (interConnection.outerTypeID) {
            foundGroupTypeIDs = union(foundGroupTypeIDs, [interConnection.outerTypeID])
          }
        })
        foundGroupTypeIDs.forEach((typeID) => {
          if (foundTypeIDs.includes(typeID)) {
            foundDuplicateTypeIDs = union(foundDuplicateTypeIDs, [typeID])
          }
          else {
            foundTypeIDs.push(typeID)
          }
        })
      }

      if (!interConnectionGroupID) {
        if (foundTypeIDs.includes(typeID)) {
          foundDuplicateTypeIDs.push(typeID)
        }
        foundTypeIDs.push(typeID)
      }

      // Show notification to user
      if (foundDuplicateTypeIDs.length) {
        const typeNameList = foundDuplicateTypeIDs.map((typeID) => {
          const type = this.allScreeningTypes.find((x) => x.id === typeID)
          return type.getTitle().toLowerCase()
        })
        this.notificationTexts.push(this.mixWB('THIS_INTERCONNECTION_COULD_NOT_BE_FORMED'))
        if (foundDuplicateTypeIDs.length === 1) {
          this.notificationTexts.push(this.mixWB('TYPE_X_WOULD_APPEAR_SEVERAL_TIMES_IN_INTERCONNECTION', [typeNameList.join(', ')]))
        }
        else {
          this.notificationTexts.push(this.mixWB('TYPES_X_WOULD_APPEAR_SEVERAL_TIMES_IN_INTERCONNECTION', [typeNameList.join(', ')]))
        }
        this.showNotificationModal = true
      }
      return !foundDuplicateTypeIDs.length
    },
    getExistingConnection({
      side,
      rowInterConnectionGroupID,
    }) {
      if (side === 'inner') {
        return this.allInterConnections.find(
          (x) => x.groupID === rowInterConnectionGroupID
            && x.outerTypeID === this.typeData.id,
        )
      }
      if (side === 'outer') {
        return this.allInterConnections.find(
          (x) => x.groupID === rowInterConnectionGroupID
            && x.innerTypeID === this.typeData.id,
        )
      }

      return false
    },
    async onInterConnectionSelect({
      side,
      choice,
      interConnectionGroupID,
      rowInterConnectionGroupID,
      typeID,
      isFirstInGroup,
      isLastInGroup,
    }) {
      // Step 1 - Check that comming connection is valid
      const isCommingConnectionValid = this.getConnectionValidStatus({
        rowInterConnectionGroupID,
        interConnectionGroupID,
        typeID,
        side,
      })
      if (!isCommingConnectionValid) {
        return
      }

      // Step 2 - Add questions
      // - Add question: Does selected type exist on its own
      const ownTypeQuestion = this.questions.find((x) => x.id === 'type-exists-on-its-own')
      const type = this.screeningTypes.find((x) => x.id === typeID)
      const resourceType = type.getResourceType()

      if (
        type.isOwnTypeAsWell === null
        && !resourceType?.options?.isNeverAlone
        && !this.typesExistsOnTheirOwnAnswers.hasOwnProperty(type.id)
      ) {
        ownTypeQuestion.question = this.mixWB(
          'IS_X_ALSO_FOUND_AS_ITS_OWN_TYPE_TEXT', [type.getTitle(true).toLowerCase()],
        )
        ownTypeQuestion.additionalData.questionTypeID = type.id
        ownTypeQuestion.showQuestion = true
      }

      // - Add question: Does entire existing connection group exist on its own?
      const ownGroupQuestion = this.questions.find((x) => x.id === 'group-exists-on-its-own')
      if (
        interConnectionGroupID
        && choice === 'all'
        && ((side === 'inner' && isLastInGroup) || (side === 'outer' && isFirstInGroup))
      ) {
        ownGroupQuestion.showQuestion = true
        ownGroupQuestion.isQuestionAwaitingResolve = true
        ownGroupQuestion.unresolvedAnswer = null
        ownGroupQuestion.question = this.mixWB(
          'IS_THIS_INTERCONNECTION_ALSO_FOUND_WITHOUT_X',
          [this.typeData.getTitle().toLowerCase()],
        )
      }

      // Step 3 - Wait for all questions to be answered
      await new Promise((resolve) => {
        const waitForQuestionsAnswered = setInterval(() => {
          if (Object.keys(this.nextQuestion).length === 0) {
            resolve()
            clearInterval(waitForQuestionsAnswered)
          }
        }, 100)
      })

      // - Get question answers
      let doesGroupExistOnItsOwn = false
      if (ownGroupQuestion.isQuestionAwaitingResolve) {
        ownGroupQuestion.isQuestionAwaitingResolve = false
        doesGroupExistOnItsOwn = !!ownGroupQuestion.unresolvedAnswer
      }

      // Step 4 - Do actions based on all known data and answers

      // - Remove existing connection
      this.onTypeReplaced({
        side,
        rowInterConnectionGroupID,
      })

      // - Make copy of existing group
      if (doesGroupExistOnItsOwn) {
        this.makeCopyOfExistingGroup({
          interConnectionGroupID,
        })
      }

      // - Single choice
      if (choice === 'single') {
        this.addSingleType({
          side,
          typeID,
          interConnectionGroupID,
          rowInterConnectionGroupID,
        })
        return
      }

      // - All choice
      this.addAllTypes({
        side,
        typeID,
        interConnectionGroupID,
        rowInterConnectionGroupID,
        isFirstInGroup,
        isLastInGroup,
      })
    },
    makeCopyOfExistingGroup({
      interConnectionGroupID,
    }) {
      // Make copy of existing selected group
      let existingInterConnectionsCopy = []
      if (interConnectionGroupID) {
        existingInterConnectionsCopy = this.allInterConnections.filter(
          (x) => x.groupID === interConnectionGroupID,
        )
      }
      const data = {
        originalGroupID: interConnectionGroupID,
        interConnections: [],
      }
      const newGroupID = setIDOnCreate(null, DB_INTER_CONNECTIONS)
      existingInterConnectionsCopy.forEach((interConnection) => {
        data.interConnections.push(new InterConnection({
          ...interConnection,
          groupID: newGroupID,
          id: null,
        }))
      })
      this.existingInterConnectionGroupsToCopy.push(data)
    },
    addInterConnectionToAddedArray(interConnection) {
      const alreadyAdded = this.addedInterConnections.find((x) => x.id === interConnection.id)
      if (!alreadyAdded) {
        this.addedInterConnections.push(interConnection)
      }
    },
    addGroupDataToAddedArray(groupAmount) {
      const alreadyAdded = this.addedInterConnectionGroupAmounts.find(
        (x) => x.id === groupAmount.id,
      )
      if (!alreadyAdded) {
        this.addedInterConnectionGroupAmounts.push(groupAmount)
      }
    },
    addSingleType({
      side,
      typeID,
      rowInterConnectionGroupID,
    }) {
      const newInterConnection = new InterConnection({
        groupID: rowInterConnectionGroupID || '',
      })

      if (side === 'inner') {
        newInterConnection.innerTypeID = typeID
        newInterConnection.outerTypeID = this.typeData.id
      }
      if (side === 'outer') {
        newInterConnection.innerTypeID = this.typeData.id
        newInterConnection.outerTypeID = typeID
      }

      this.addedInterConnections.push(newInterConnection)

      // Check to specify split status
      this.checkToAutoSetSplitStatus({
        side,
        typeID,
        interConnection: newInterConnection,
      })
    },
    addAllTypes({
      side,
      typeID,
      interConnectionGroupID,
      rowInterConnectionGroupID,
      isFirstInGroup,
      isLastInGroup,
    }) {
      let newInterConnection = null
      const newGroupID = setIDOnCreate(null, DB_INTER_CONNECTIONS)

      // Add to entire inner group
      if (side === 'inner' && isLastInGroup) {
        newInterConnection = new InterConnection({
          groupID: interConnectionGroupID,
          innerTypeID: typeID,
          outerTypeID: this.typeData.id,
        })
        this.addedInterConnections.push(newInterConnection)
      }

      // Add to apart of the inner group
      else if (side === 'inner' && !isLastInGroup) {
        const interConnectionGroup = InterConnection.getGroupInterConnectionsFromTypeID({
          typeID,
          interConnectionGroupID,
          side,
          interConnections: this.allInterConnections,
        })
        newInterConnection = new InterConnection({
          groupID: newGroupID,
          innerTypeID: typeID,
          outerTypeID: this.typeData.id,
        })
        this.addedInterConnections.push(newInterConnection)
        interConnectionGroup.forEach((interConnection) => {
          this.addedInterConnections.push(new InterConnection({
            ...interConnection,
            id: '',
            groupID: newGroupID,
          }))
        })

        // Reset group ID from partly used group
        interConnectionGroupID = null
      }

      // Add to entire outer group
      if (side === 'outer' && isFirstInGroup) {
        newInterConnection = new InterConnection({
          groupID: interConnectionGroupID,
          innerTypeID: this.typeData.id,
          outerTypeID: typeID,
        })
        this.addedInterConnections.push(newInterConnection)
      }

      // Add to apart of the outer group
      if (side === 'outer' && !isFirstInGroup) {
        const interConnectionGroup = InterConnection.getGroupInterConnectionsFromTypeID({
          typeID,
          interConnectionGroupID,
          side,
          interConnections: this.allInterConnections,
        })
        newInterConnection = new InterConnection({
          groupID: newGroupID,
          innerTypeID: this.typeData.id,
          outerTypeID: typeID,
        })
        this.addedInterConnections.push(newInterConnection)
        interConnectionGroup.forEach((interConnection) => {
          this.addedInterConnections.push(new InterConnection({
            ...interConnection,
            id: '',
            groupID: newGroupID,
          }))
        })

        // Reset group ID from partly used group
        interConnectionGroupID = null
      }

      // Update group ids for group being added to inner group
      if (rowInterConnectionGroupID) {
        const interConnections = this.allInterConnections.filter(
          (x) => x.groupID === rowInterConnectionGroupID,
        )
        this.onRenameGroupIDForInterConnections({
          interConnections,
          newGroupID: interConnectionGroupID || newGroupID,
        })
      }

      // Check to specify split status
      this.checkToAutoSetSplitStatus({
        side,
        typeID,
        interConnection: newInterConnection,
      })
    },
    onRenameGroupIDForInterConnections({
      interConnections,
      oldGroupID,
      newGroupID,
    }) {
      interConnections.forEach((interConnection) => {
        // Remove old inter connection
        this.removedInterConnectionIDs.push(interConnection.id)

        // Add new inter connections
        this.addedInterConnections.push(new InterConnection({
          ...interConnection,
          id: '',
          groupID: newGroupID,
        }))
      })

      // Check to dublicate amount
      const groupAmount = this.allInterConnectionGroupAmounts.find(
        (x) => x.id === oldGroupID,
      )
      if (groupAmount) {
        const newGroupAmount = {
          ...groupAmount,
          id: newGroupID,
        }
        this.addedInterConnectionGroupAmounts.push(newGroupAmount)
      }
    },
    onSplitStatusUpdate({ interConnectionID, status }) {
      const interConnection = this.allInterConnections.find((x) => x.id === interConnectionID)
      interConnection.splitStatus = status

      this.addInterConnectionToAddedArray(interConnection)
    },
    onEffectStatusUpdate({ interConnectionID, status }) {
      const interConnection = this.allInterConnections.find((x) => x.id === interConnectionID)
      interConnection.effectDirection = status

      this.addInterConnectionToAddedArray(interConnection)
    },
    onAreaUpdate({
      value,
      rowInterConnectionGroupID,
    }) {
      let groupAmount = this.allInterConnectionGroupAmounts.find(
        (x) => x.id === rowInterConnectionGroupID,
      )

      if (!groupAmount) {
        groupAmount = {
          id: rowInterConnectionGroupID,
          m2: 0,
        }
      }

      groupAmount.m2 = value

      this.addGroupDataToAddedArray(groupAmount)
    },
    onTypeReplaced({
      side,
      rowInterConnectionGroupID,
    }) {
      const existingConnection = this.getExistingConnection({
        side,
        rowInterConnectionGroupID,
      })

      if (!existingConnection) {
        return
      }

      this.removedInterConnectionIDs.push(existingConnection.id)
      let interConnections = []

      if (side === 'inner') {
        interConnections = InterConnection.getGroupInterConnectionsFromTypeID({
          typeID: existingConnection.innerTypeID,
          interConnectionGroupID: existingConnection.groupID,
          side: 'inner',
          interConnections: this.allInterConnections,
        }).filter((x) => x.id !== existingConnection.id)
      }

      if (side === 'outer') {
        interConnections = InterConnection.getGroupInterConnectionsFromTypeID({
          typeID: existingConnection.outerTypeID,
          interConnectionGroupID: existingConnection.groupID,
          side: 'outer',
          interConnections: this.allInterConnections,
        }).filter((x) => x.id !== existingConnection.id)
      }

      if (interConnections.length) {
        const newGroupID = setIDOnCreate(null, DB_INTER_CONNECTIONS)
        this.onRenameGroupIDForInterConnections({
          interConnections,
          newGroupID,
        })
      }
    },
    onNoTypeSelected({ interConnectionID, removedTypeID }) {
      if (!interConnectionID) {
        return
      }
      const interConnection = this.allInterConnections.find((x) => x.id === interConnectionID)

      // Give second group a different grouop ID if two groups are detected
      const innerInterConnections = InterConnection.getGroupInterConnectionsFromTypeID({
        typeID: removedTypeID,
        interConnectionGroupID: interConnection.groupID,
        side: 'inner',
        interConnections: this.allInterConnections,
      }).filter((x) => x.id !== interConnectionID)
      const outerInterConnections = InterConnection.getGroupInterConnectionsFromTypeID({
        typeID: removedTypeID,
        interConnectionGroupID: interConnection.groupID,
        side: 'outer',
        interConnections: this.allInterConnections,
      }).filter((x) => x.id !== interConnectionID)

      if (innerInterConnections.length && outerInterConnections.length) {
        const newGroupID = setIDOnCreate(null, DB_INTER_CONNECTIONS)
        this.onRenameGroupIDForInterConnections({
          interConnections: outerInterConnections,
          oldGroupID: interConnection.groupID,
          newGroupID,
        })
      }

      // Remove entry for type existing on its own
      delete this.typesExistsOnTheirOwnAnswers[removedTypeID]

      // Remove entry for duplicating group
      const duplicateIndex = this.existingInterConnectionGroupsToCopy.findIndex(
        (x) => x.originalGroupID === interConnection.groupID,
      )
      if (duplicateIndex > -1) {
        this.existingInterConnectionGroupsToCopy.splice(duplicateIndex, 1)
      }

      // Remove inter connection
      this.removedInterConnectionIDs.push(interConnectionID)
    },
    // eslint-disable-next-line no-unused-vars
    onQuestionAnswer({ answer, questionID, additionalData }) {
      if (questionID === 'type-exists-on-its-own') {
        this.$set(this.typesExistsOnTheirOwnAnswers, additionalData.questionTypeID, answer)
      }

      if (questionID === 'group-exists-on-its-own') {
        const ownGroupQuestion = this.questions.find((x) => x.id === 'group-exists-on-its-own')
        ownGroupQuestion.unresolvedAnswer = answer
      }

      this.onQuestionClose()
    },
    onQuestionClose() {
      const question = this.questions.find((x) => x.id === this.nextQuestion.id)
      question.showQuestion = false
    },
    onNotificationClose() {
      this.showNotificationModal = false
      this.notificationTexts = []
    },
    onCheckBoxClick() {
      this.$emit('exists-on-its-own-update')
    },
    checkToAutoSetSplitStatus({
      interConnection,
    }) {
      const innerType = this.allScreeningTypes.find((x) => x.id === interConnection.innerTypeID)
      const innerResourceType = innerType.getResourceType()
      const innerTypeWDGID = innerType.getWDGID()

      const outerType = this.allScreeningTypes.find((x) => x.id === interConnection.outerTypeID)
      const outerResourceType = outerType.getResourceType()
      const outerTypeWDGID = outerType.getWDGID

      // Case 1 - Outer type is wallpaper
      if (outerResourceType?.id === 'T-64') {
        // Inner type is WDG-2
        if (innerTypeWDGID === 'WDG-2') {
          interConnection.splitStatus = 'splitable'
          interConnection.isSplitStatusLocked = true
          interConnection.isCleanableConnection = true
        }
        // Inner type is not WDG-2
        else {
          interConnection.splitStatus = 'stuck'
        }
      }

      // Case 2 - Inner or outer type has isNeverAlone = true
      else if (
        innerResourceType?.options?.isNeverAlone
        || outerResourceType?.options?.isNeverAlone
      ) {
        interConnection.splitStatus = 'splitable'
        interConnection.effectDirection = 'both'
      }

      // Case 3 - When inner or outer type has WDG-2
      else if (innerTypeWDGID === 'WDG-2' || outerTypeWDGID === 'WDG-2') {
        interConnection.splitStatus = 'splitable'
        interConnection.isSplitStatusLocked = true
        interConnection.isCleanableConnection = true
      }
    },
    checkOnUnitsChanged(newUnitIDs) {
      if (!this.interConnectionGroups.length) {
        EventBus.$emit('inter-connections-units-check-status', [])
      }

      const tempMainType = new Type({
        ...JSON.parse(JSON.stringify(this.typeData)),
        unitIDs: newUnitIDs,
      })
      const interConnections = this.allInterConnections.filter(
        (x) => x.outerTypeID === tempMainType.id || x.innerTypeID === tempMainType.id,
      )

      const foundTypes = []
      interConnections.forEach((interConnection) => {
        let innerType = null
        let outerType = null

        if (interConnection.outerTypeID === tempMainType.id) {
          innerType = this.allScreeningTypes.find((x) => x.id === interConnection.innerTypeID)
        }
        else {
          outerType = this.allScreeningTypes.find((x) => x.id === interConnection.outerTypeID)
        }

        let matchFound = false
        if (innerType) {
          matchFound = InterConnection.checkInterConnectionUnits(innerType, tempMainType)
        }
        else {
          matchFound = InterConnection.checkInterConnectionUnits(tempMainType, outerType)
        }

        if (!matchFound) {
          foundTypes.push(innerType || outerType)
        }
      })

      EventBus.$emit('inter-connections-units-check-status', foundTypes)
    },
  },
  components: {
    InterConnectionRow,
    SectionHeadline,
    Checkbox,
    Dialog,
  },
  created() {
    EventBus.$on('split-status-update', this.onSplitStatusUpdate)
    EventBus.$on('effect-status-update', this.onEffectStatusUpdate)
    EventBus.$on('check-inter-connections-on-units-change', this.checkOnUnitsChanged)
  },
  destroyed() {
    EventBus.$off('split-status-update', this.onSplitStatusUpdate)
    EventBus.$off('effect-status-update', this.onEffectStatusUpdate)
    EventBus.$off('check-inter-connections-on-units-change', this.checkOnUnitsChanged)
  },
}
</script>

<style lang="stylus" scoped>
  .InterConnectionsSelect
    padding-top 15px
    padding-bottom 10px
</style>
