<template>
  <div class="AddOptions">
    <div
      class="Option"
      v-for="option in optionList"
      :key="option.id"
      @click="$emit('option-click', option.id)"
      v-show="option.isShowing">
      <div class="IconWrap">
        <PlusIcon />
      </div>
      <span>{{ option.title }}</span>
    </div>
  </div>
</template>

<script>
import PlusIcon from '@/assets/svg/plus.svg?inline'

export default {
  name: 'AddOptions',
  props: {
    addOptions: {
      type: Object,
      required: true,
    },
  },
  computed: {
    optionList() {
      return Object.entries(this.addOptions).reduce((prev, [key, value]) => {
        let title = ''

        switch (key) {
        case 'note':
          title = this.mixWB('NOTE')
          break
        case 'rooms':
          title = this.mixWB('ROOMS')
          break
        case 'condition':
          title = this.mixWB('CONDITION')
          break
        case 'reuseOrPrevention':
          title = this.mixWB('REUSE_AND_PREVENTION')
          break
        default:
          break
        }

        prev.push({
          id: key,
          title,
          isShowing: !value.isSelected && value.isAvailable,
        })
        return prev
      }, [])
    },
  },
  components: {
    PlusIcon,
  },

}
</script>

<style lang="stylus" scoped>
  .AddOptions
    margin-top 10px
    margin-bottom 30px
    border-top 1px solid $color_grey_lighter
    .Option
      display flex
      align-items center
      border-bottom 1px solid $color_grey_lighter
      height 50px
      padding-left 10px
      cursor pointer
      .IconWrap
        box(12px)
        flex-center-children()
        margin-right 10px
        svg
          fill $color_primary
      span
        text-transform uppercase

</style>
