<template>
  <div
    class="InterConnectionSelectBox"
    :class="rootClasses">
    <div
      v-if="isEmpty"
      class="PaddingTop"/>

    <!-- No type -->
    <div
      v-if="isEmpty"
      class="Empty">
      <span>+</span>
      <div class="IconWrap">
        <AngleRightIcon />
      </div>
    </div>

    <!-- Has type -->
    <div
      v-else
      class="Type">
      <!-- Has image -->
      <div
        v-if="type.images.length"
        class="ImageWrap">
        <MultiImage
          :image="type.images[0]"
          transformation="square_360" />
      </div>
      <!-- No image -->
      <div
        v-else
        class="NoImage"/>
      <div class="TitleWrap">
        <span>{{ type.getTitle() }}</span>
      </div>
      <div
        v-if="interConnection"
        class="InterConnectionIconsWrap">
        <InterConnectionIcons
          :interConnection="interConnection"
          :isVertical="!!side"
          :useSplitStatusMenu="!!side"
          @split-status-selected="onInnerSplitStatusSelected"
          @effect-status-selected="onInnerEffectStatusSelected" />
      </div>
    </div>
  </div>
</template>

<script>
import AngleRightIcon from '@/assets/svg/angle-right.svg?inline'
import MultiImage from '@/components/Images/MultiImage.vue'
import InterConnectionIcons from '@/components/ScreeningItems/InterConnectionIcons.vue'
import EventBus from '@/EventBus'

export default {
  name: 'InterConnectionSelectBox',
  props: {
    side: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: [Object, Boolean],
      required: false,
      default: false,
    },
    interConnection: {
      type: [Object, Boolean],
      required: false,
      default: false,
    },
    isMainType: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOutsideScope: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    rootClasses() {
      return {
        Inner: this.side === 'inner',
        Outer: this.side === 'outer',
        IsEmpty: this.isEmpty,
        IsMainType: this.isMainType,
        IsOutsideScope: this.isOutsideScope,
      }
    },
    isEmpty() {
      if (!this.type) {
        return true
      }
      return false
    },
  },
  methods: {
    onInnerSplitStatusSelected(status) {
      this.$emit('updated')
      EventBus.$emit('split-status-update', { interConnectionID: this.interConnection.id, status })
    },
    onInnerEffectStatusSelected(status) {
      this.$emit('updated')
      EventBus.$emit('effect-status-update', { interConnectionID: this.interConnection.id, status })
    },
  },
  components: {
    AngleRightIcon,
    MultiImage,
    InterConnectionIcons,
  },
}
</script>

<style lang="stylus" scoped>
  .InterConnectionSelectBox
    position relative
    width 100%
    border 1px solid $color_grey_lighter
    cursor pointer
    margin-top 5px
    &.IsEmpty
      border 1px dashed $color_grey_lighter
    &.IsOutsideScope
      display block
      >>> img
        opacity 0.5
    &.IsMainType
      border 1px solid $color_primary
      .Type
        &:before
          content ''
          position absolute
          top -5px
          left -5px
          z-index -1
          width calc(100% + 10px)
          height calc(100% + 6px)
          background-color $color_primary
        .TitleWrap
          span
            background-color $color_primary
            color #fff

  .PaddingTop
    padding-top 100%

  .Empty
    position absolute
    top 0
    left 0
    box(100%)
    flex-center-children-column()
    justify-content center
    span
      text-transform uppercase
      color $color_grey_lighter
      line-height 1
      font-size 3rem
    .IconWrap
      position absolute
      top calc(50% - 9px)
      box(12px)
      .Inner &
        right -13px
      .Outer &
        left -13px

  .Type
    .ImageWrap
      >>> .Inner
        z-index -1
    .NoImage
      padding-top 100%
      background-color $color_grey_lightest
    .TitleWrap
      margin-top -20px
      span
        width 100%
        text-transform uppercase
        text-align center
        background-color $color_grey_lighter
        font-size 0.75rem
        height 20px
        truncated()
        width 100%
        padding 0 5px
        flex-center-children()
    .InterConnectionIconsWrap
      position absolute
      z-index 1
      top calc((100% - 84px) / 2)
      left -24px
</style>
