<template>
  <div class="CategoryTypeSelect">
    <SectionHeadline :text="mixWB('WHAT_TYPE_IS_THIS')" />

    <div class="TypeList">
      <!-- Predefined options -->
      <div
        class="Type"
        :class="{ IsSelected: item.isSelected }"
        v-for="item in categoryTypeList.items"
        :key="item.id"
        @click="onListItemClick(item)">
        <span>{{ item.title }}</span>
      </div>

      <!-- Other option -->
      <div
        class="Type"
        :class="{ 'IsSelected': data.isOtherType }"
        @click="onOtherClick()">
        <span>{{ mixWB('OTHER') }}</span>
      </div>
    </div>

    <!-- Search for type -->
    <div
      v-if="data.isOtherType"
      class="SearchTypeWrap">
      <SectionHeadline :text="mixWB('SEARCH_FOR_A_TYPE')" />
      <SearchSelect
        name="typeSelect"
        :data="data.typeID"
        :customText="data.customTypeName"
        :itemList="typesAsArray"
        allowCustomText
        @update="onTypeSelectUpdate"
        @custom-text-update="onCustomTextUpdate" />
    </div>

    <!-- Group type options -->
    <div
      v-if="selectedGroup"
      class="GroupTypesWrap">
      <SectionHeadline :text="mixWB('SELECT_TYPE_OF_X', [selectedGroup.title])" />
      <div class="TypeList">
        <div
          class="Type"
          :class="{ 'IsSelected': item.isSelected }"
          v-for="item in selectedGroup.items"
          :key="item.id"
          @click="onListItemClick(item)">
          <span>{{ item.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import { mapGetters } from 'vuex'
import SearchSelect from '@/components/FormElements/SearchSelect.vue'
import { sortBy } from 'lodash-es'

export default {
  name: 'CategoryTypeSelect',
  props: {
    categoryID: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedGroupID: null,
      cleanData: {
        categoryTypeID: '',
        typeID: '',
        isOtherType: false,
        customTypeName: '',
        customTypeWDGID: '',
      },
    }
  },
  computed: {
    ...mapGetters([
      'typesAsArray',
      'categoryTypesAsArray',
      'categoryTypeGroupsAsArray',
    ]),
    categoryTypeList() {
      const items = []
      const groups = []

      this.categoryTypesAsArray.forEach((categoryType) => {
        if (categoryType.categoryID !== this.categoryID) {
          return
        }

        const item = {
          id: categoryType.id,
          isSelected: false,
          isGroup: false,
          title: this.mixWB(categoryType.getTitle()),
        }

        if (this.data.categoryTypeID === categoryType.id) {
          item.isSelected = true
        }

        const resourceType = categoryType.getResourceType()
        // Check to add to group
        if (resourceType.materialIDs.length === 1) {
          const material = resourceType.getFirstResourceMaterial()
          const categoryTypeGroup = this.categoryTypeGroupsAsArray.find(
            (x) => x.materialIDs.includes(material.id),
          )

          if (categoryTypeGroup) {
            let groupToUse = groups.find((x) => x.groupID === categoryTypeGroup.id)
            if (!groupToUse) {
              groupToUse = {
                groupID: categoryTypeGroup.id,
                isSelected: false,
                title: this.mixWB(categoryTypeGroup.translation),
                items: [],
              }
              groups.push(groupToUse)
            }

            if (item.isSelected) {
              groupToUse.isSelected = true
              this.selectedGroupID = groupToUse.groupID
            }

            groupToUse.isSelected = groupToUse.groupID === this.selectedGroupID
            groupToUse.items.push(item)
            return
          }
        }

        items.push(item)
      })

      // Go through groups
      const groupsToUse = []
      groups.forEach((group) => {
        if (group.items.length === 1) {
          items.push(group.items[0])
          return
        }
        items.push({
          id: group.groupID,
          isGroup: true,
          title: group.title,
          groupID: group.groupID,
          isSelected: group.isSelected,
        })
        groupsToUse.push(group)
      })

      return {
        items: sortBy(items, ['title']),
        groups: groupsToUse,
      }
    },
    selectedGroup() {
      if (!this.selectedGroupID) {
        return null
      }
      return this.categoryTypeList.groups.find((x) => x.groupID === this.selectedGroupID)
    },
  },
  methods: {
    getCleanData() {
      this.selectedGroupID = null
      return { ...this.cleanData }
    },
    onListItemClick(item) {
      if (
        this.isDisabled
        || this.selectedGroupID === item.id
        || this.data.categoryTypeID === item.id
      ) {
        return
      }

      const data = this.getCleanData()

      // Group click
      if (item.isGroup) {
        this.selectedGroupID = item.groupID
        this.emitData(data, false)
        return
      }

      // Category type click
      data.categoryTypeID = item.id
      this.emitData(data, true)
    },
    onOtherClick() {
      if (this.data.isOtherType) {
        return
      }

      const data = this.getCleanData()
      data.isOtherType = true

      this.emitData(data, false)
    },
    onTypeSelectUpdate({ value }) {
      const data = this.getCleanData()
      data.isOtherType = true
      data.typeID = value

      this.emitData(data, !!value)
    },
    onCustomTextUpdate({ value }) {
      const data = this.getCleanData()
      data.isOtherType = true
      data.customTypeName = value

      this.emitData(data, !!value)
    },
    emitData(data, isDone) {
      this.$emit('update', { data, isDone })
    },
  },
  components: {
    SectionHeadline,
    SearchSelect,
  },

}
</script>

<style lang="stylus" scoped>
  .CategoryTypeSelect
    padding-bottom 5px

  .TypeList
    optionList()

  .SearchTypeWrap
    margin-bottom 20px
</style>
