<template>
  <div class="ConditionSelect">
    <SectionHeadline :text="title" />

    <div class="OptionsWrap">
      <RadioButton
        group-name="condition"
        text="1"
        value="1"
        :selectedValue="typeData.condition || ''"
        @radio-button-click="onClick" />
      <RadioButton
        group-name="condition"
        text="2"
        value="2"
        :selectedValue="typeData.condition || ''"
        @radio-button-click="onClick" />
      <RadioButton
        group-name="condition"
        text="3"
        value="3"
        :selectedValue="typeData.condition || ''"
        @radio-button-click="onClick" />
      <RadioButton
        group-name="condition"
        text="4"
        value="4"
        :selectedValue="typeData.condition || ''"
        @radio-button-click="onClick" />
    </div>
    <span
      class="ConditionText">{{ conditionText }}</span>
  </div>
</template>

<script>
import RadioButton from '@/components/FormElements/RadioButton.vue'
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'

export default {
  name: 'ConditionSelect',
  props: {
    typeData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    title() {
      return this.typeData?.getTitle
        ? this.mixWB('WHAT_IS_THE_CONDITION_OF_X', [this.typeData.getTitle()])
        : ''
    },
    conditionText() {
      let text = `${ this.mixWB('CONDITION') }:`

      switch (this.typeData.condition) {
      case '1':
        text += ` ${ this.mixWB('VERY_GOOD') }`
        break
      case '2':
        text += ` ${ this.mixWB('GOOD') }`
        break
      case '3':
        text += ` ${ this.mixWB('BAD') }`
        break
      case '4':
        text += ` ${ this.mixWB('VERY_BAD') }`
        break
      default:
        text += ` ${ this.mixWB('NOT_ASSESSED') }`
        break
      }

      return text
    },
  },
  methods: {
    onClick(value) {
      this.$emit('update', value)
    },
  },
  components: { SectionHeadline, RadioButton },
}
</script>

<style lang="stylus" scoped>
  .ConditionSelect
    padding-top 10px
    padding-bottom 10px

  .OptionsWrap
    display flex
    >>> .RadioButton
      margin-right 20px

  .ConditionText
    color $color_grey
    font-size 0.875rem
</style>
