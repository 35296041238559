<template>
  <div class="SurfaceAutoType">
    <!-- Title -->
    <span class="Title">{{ mixWB(this.resourceType.translation) }}</span>

    <!-- Question -->
    <div class="Section">
      <SectionHeadline
        :text="getQuestionTitle()" />
      <div class="OptionsWrap">
        <div
          class="Option"
          :class="{ IsSelected: selectedAnswer === 'no' }"
          @click="onOptionNoClick">
          <span>{{ mixWB('NO') }}</span>
        </div>
        <div
          class="Option"
          :class="{ IsSelected: selectedAnswer === 'yes' }"
          @click="onOptionYesClick">
          <span>{{ mixWB('YES') }}</span>
        </div>
      </div>
    </div>

    <!-- Images -->
    <div
      v-show="activeStepNo >= 1"
      class="Section">
      <ImageSelect
        :groupName="groupName"
        :categoryID="categoryID"
        @image-list-update="onImageListUpdate" />
    </div>

    <!-- Coating -->
    <div
      v-show="activeStepNo >= 2"
      class="Section">
      <CoatingSelect
        :resetCoatingCounter="resetCoatingCounter"
        :groupName="groupName"
        :typeName="mixWB(resourceType.translation)"
        @coating-updated="onCoatingUpdated"
        @coating-finished="onCoatingFinished" />
    </div>

    <!-- Condition -->
    <ConditionSelect
      v-show="activeStepNo >= 3"
      :typeData="type"
      @update="onConditionUpdate" />

    <!-- Amount -->
    <AmountsSelect
      v-if="type.id"
      v-show="activeStepNo >= 3"
      :isActive="activeStepNo === 3"
      :screeningType="type"
      :amountToCopy="screeningType.amounts[0]"
      @update-on-load="onAmountsUpdateOnLoad"
      @update="onAmountsUpdate"
      @finished="onAmountsFinished" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import ImageSelect from '@/components/ScreeningTypeSections/ImageSelect.vue'
import CoatingSelect from '@/components/ScreeningTypeSections/CoatingSelect.vue'
import Type from '@/globals/javascript/models/Type'
import AmountsSelect from '@/components/ScreeningTypeSections/AmountsSelect.vue'
import ConditionSelect from '@/components/ScreeningTypeSections/ConditionSelect.vue'

export default {
  name: 'SurfaceAutoType',
  props: {
    screeningType: {
      type: Object,
      required: true,
    },
    resourceTypeID: {
      type: String,
      required: true,
    },
    categoryID: {
      type: String,
      required: true,
    },
    activeNo: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      activeStepNo: 0,
      selectedAnswer: '',
      resetCoatingCounter: 0,
      type: {},
      roadMap: [
        'question', // 0
        'images', // 1
        'coating', // 2
        'amount', // 3
      ],
    }
  },
  computed: {
    ...mapGetters([
      'typesAsObject',
    ]),
    activeStep() {
      return this.roadMap[this.activeStepNo]
    },
    resourceType() {
      return this.typesAsObject[this.resourceTypeID]
    },
    groupName() {
      return `auto-type-${ this.resourceType.id }`
    },
  },
  methods: {
    getQuestionTitle() {
      return this.mixWB('IS_THERE_X', [this.mixWB(this.resourceType.translation).toLowerCase()])
    },
    onOptionYesClick() {
      if (this.selectedAnswer === 'yes') {
        return
      }

      this.$emit('auto-type-update', { resourceTypeID: this.resourceTypeID })

      this.selectedAnswer = 'yes'

      this.type = new Type({
        ...this.type,
        autoTypeBaseID: this.screeningType.id,
        type: {
          isOtherType: true,
          typeID: this.resourceTypeID,
        },
        unitIDs: this.screeningType.unitIDs,
      })

      this.onNextStep()
    },
    onOptionNoClick() {
      if (this.selectedAnswer === 'no') {
        return
      }

      this.$emit('auto-type-update', { resourceTypeID: this.resourceTypeID })

      this.activeStepNo = 0

      this.selectedAnswer = 'no'

      this.onComplete()
    },
    onImageListUpdate(imageList) {
      this.$emit('auto-type-update', { resourceTypeID: this.resourceTypeID })
      this.type.images = imageList

      if (!imageList.length) {
        this.activeStepNo = 1
        return
      }

      this.onNextStep()
    },
    onCoatingUpdated() {
      this.activeStepNo = 2
      this.$emit('auto-type-update', { resourceTypeID: this.resourceTypeID })
    },
    onCoatingFinished(coatingData) {
      this.$set(this.type, 'coating', coatingData)
      this.onNextStep()
    },
    onAmountsUpdateOnLoad(amounts) {
      this.type.amounts = amounts
    },
    onAmountsUpdate() {
      this.activeStepNo = 3
      this.$emit('auto-type-update', { resourceTypeID: this.resourceTypeID })
    },
    onAmountsFinished(amounts) {
      this.type.amounts = amounts
      this.onNextStep()
    },
    onConditionUpdate(value) {
      if (this.type.condition === value) {
        this.type.condition = ''
      }
      else {
        this.type.condition = value
      }
    },
    onNextStep() {
      if (this.activeStep === 'question') {
        this.activeStepNo += 1
        // Check if images are done
        if (this.type.images.length) {
          this.onNextStep()
        }
        return
      }

      if (this.activeStep === 'images') {
        this.activeStepNo += 1
        // Check if coating is done
        if (this.type.coating.mainOptionID) {
          this.onNextStep()
        }
        return
      }

      if (this.activeStep === 'coating') {
        this.activeStepNo += 1
        // Check if amounts are done
        if (this.type?.amounts?.[0]?.data?.type) {
          this.activeStepNo += 1
          this.onNextStep()
        }
        return
      }

      if (this.activeStep === 'amount') {
        this.activeStepNo += 1
      }

      this.onComplete()
    },
    onComplete() {
      this.$emit('auto-type-complete', {
        answer: this.selectedAnswer,
        resourceTypeID: this.resourceTypeID,
        type: this.type,
      })
    },
  },
  components: {
    SectionHeadline,
    ImageSelect,
    CoatingSelect,
    AmountsSelect,
    ConditionSelect,
  },
}
</script>

<style lang="stylus" scoped>
  .SurfaceAutoType
    span
      display block

  .Title
    text-transform uppercase
    margin-bottom 10px
    font-size 1.125rem

  .Section
    margin-bottom 10px

  .OptionsWrap
    display flex
    align-items center

  .Option
    display flex
    align-items center
    justify-content center
    width calc(33.33% - 7px)
    margin-right 10px
    margin-bottom 10px
    height 40px
    background-color $color_grey_lightest
    border 1px solid lighten($color_grey_lighter, 25%)
    box-shadow $box_shadow_1
    cursor pointer
    span
      text-align center
      text-transform uppercase
      font-size 0.875rem
    &.IsSelected
      background-color $color_primary
      border-color $color_primary
      box-shadow $box_shadow_1
      span
        color #fff

  >>> .ConditionSelect
    padding-top 0
    margin-top -10px
</style>
