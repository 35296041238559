<template>
  <div class="SurfaceAutoTypeSelect">
    <template v-for="autoType in surfaceAutoTypes">

      <div
        class="AutoTypeItem"
        :key="autoType.resourceTypeID"
        v-if="autoType.show"
        v-show="activeStepNo >= autoType.activeNo">
        <SurfaceAutoType
          :screeningType="screeningType"
          :resourceTypeID="autoType.resourceTypeID"
          :categoryID="category.id"
          :activeNo="autoType.activeNo"
          @auto-type-update="onAutoTypeUpdate"
          @auto-type-complete="onAutoTypeComplete" />
      </div>
    </template>
  </div>
</template>

<script>
import EventBus from '@/EventBus'
import { mapGetters } from 'vuex'
import AutoTypeSelect from '@/globals/javascript/models/AutoTypeSelect'
import SurfaceAutoType from '@/components/ScreeningTypeSections/SurfaceAutoType.vue'

export default {
  name: 'SurfaceAutoTypeSelect',
  props: {
    screeningType: {
      type: Object,
      required: true,
    },
    resourceType: {
      type: [Object, Boolean],
      required: true,
    },
    resourceCategoryType: {
      type: [Object, Boolean],
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeStepNo: 0,
      surfaceAutoTypes: [],
    }
  },
  computed: {
    ...mapGetters([
      'typesAsArray',
    ]),
    showPlaster() {
      // Check category
      if (!this.category.options.askForPlaster) {
        return false
      }

      // Check category type
      if (
        this.resourceCategoryType
        && !this.resourceCategoryType.options.askForPlaster
      ) {
        return false
      }

      // Check type
      if (
        this.resourceType
        && [
          'T-56',
          'T-64',
        ].includes(this.resourceType.id)
      ) {
        return false
      }

      return true
    },
    showWallpaper() {
      // Check category
      if (!this.category.options.askForWallpaper) {
        return false
      }

      // Check category type
      if (
        this.resourceCategoryType
        && !this.resourceCategoryType.options.askForWallpaper
      ) {
        return false
      }

      // Check type
      if (
        this.resourceType
        && [
          'T-64',
        ].includes(this.resourceType.id)
      ) {
        return false
      }

      return true
    },
    overallShowStatus() {
      return !!(this.showPlaster || this.showWallpaper)
    },
  },
  methods: {
    setOverallShowStatusOnLoad() {
      this.$emit('update-show-status', this.overallShowStatus)
    },
    setAutoTypesOnLoad() {
      // Plaster
      let counter = 0
      let isFirstStepFound = false
      this.surfaceAutoTypes.push(new AutoTypeSelect({
        resourceTypeID: 'T-56',
        activeNo: counter,
        show: this.showPlaster,
        side: 'outer',
      }))
      if (!isFirstStepFound && this.showPlaster) {
        this.activeStepNo = counter
        isFirstStepFound = true
      }

      // Wallpaper
      counter += 1
      this.surfaceAutoTypes.push(new AutoTypeSelect({
        resourceTypeID: 'T-64',
        activeNo: counter,
        show: this.showWallpaper,
        side: 'outer',
      }))
      if (!isFirstStepFound && this.showWallpaper) {
        this.activeStepNo = counter
        isFirstStepFound = true
      }
    },
    onAutoTypeUpdate({ resourceTypeID }) {
      const autoType = this.surfaceAutoTypes.find((x) => x.resourceTypeID === resourceTypeID)

      this.activeStepNo = autoType.activeNo
      this.$emit('auto-type-update')
    },
    onAutoTypeComplete({ resourceTypeID, type, answer }) {
      const autoType = this.surfaceAutoTypes.find((x) => x.resourceTypeID === resourceTypeID)

      // Set item
      autoType.answer = answer
      autoType.type = type

      // Next
      this.activeStepNo = autoType.activeNo
      this.onNextStep()
    },
    onNextStep() {
      let stopped = false
      this.surfaceAutoTypes.forEach((autoType) => {
        if (stopped) {
          return
        }

        if (autoType.activeNo <= this.activeStepNo) {
          return
        }

        if (autoType.show) {
          // Not done
          if (!autoType.answer) {
            this.activeStepNo = autoType.activeNo
            stopped = true
            return
          }
        }

        this.activeStepNo += 1
      })

      if (stopped) {
        return
      }

      this.onComplete()
    },
    onComplete() {
      this.$emit(
        'auto-types-finished',
        this.surfaceAutoTypes,
      )
    },
    onAutoTypeReset() {
      this.surfaceAutoTypes = []
      this.activeStepNo = 0
      requestAnimationFrame(() => {
        this.setOverallShowStatusOnLoad()
        this.setAutoTypesOnLoad()
      })
    },
  },
  components: {
    SurfaceAutoType,
  },
  created() {
    this.onAutoTypeReset()
    EventBus.$on('auto-type-reset', this.onAutoTypeReset)
  },
  destroyed() {
    EventBus.$off('auto-type-reset', this.onAutoTypeReset)
  },
}
</script>

<style lang="stylus" scoped>
  .SurfaceAutoTypeSelect
    margin 0 -20px 0px
    background-color $color_grey_lightest
    +below($tablet)
      margin 0 -15px 0px

  .AutoTypeItem
    padding 15px 20px 10px
    margin-bottom 10px
    border-top 1px solid $color_grey_lighter
    &:last-child
      border-bottom 1px solid $color_grey_lighter
</style>
