<template>
  <div class="WorkDescriptionGroupSelect">
    <SectionHeadline :text="mixWB('WORK_DESCRIPTION_GROUP')" />

    <div class="GroupList">
      <template
        v-for="wdg in wdgAsArray">
        <div
          v-if="!wdg.options.isUsedForMultiType"
          :key="wdg.id"
          class="Group"
          :class="{ 'IsSelected': wdg.id === selectedID }"
          @click="onGroupClick(wdg)">
          <span class="OptionText">{{ mixWB(wdg.translation) }}</span>
        </div>

      </template>
    </div>
  </div>
</template>

<script>
import SectionHeadline from '@/components/FormElements/SectionHeadline'
import { mapGetters } from 'vuex'

export default {
  name: 'WorkDescriptionGroupSelect',
  props: {
    selectedID: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'wdgAsArray',
    ]),
  },
  methods: {
    onGroupClick(group) {
      this.$emit('group-click', group.id)
    },
  },
  components: {
    SectionHeadline,
  },

}
</script>

<style lang="stylus" scoped>
  .WorkDescriptionGroupSelect
    margin-top -5px
    padding-top 0px
    padding-bottom 10px

  .GroupList
    display flex
    flex-wrap wrap
    margin 0px -5px

  .Group
    display flex
    align-items center
    justify-content center
    min-width 70px
    min-height 40px
    padding 5px 10px
    margin 0px 5px 10px
    background-color $color_grey_lightest
    border 1px solid lighten($color_grey_lighter, 25%)
    box-shadow $box_shadow_1
    cursor pointer
    span
      display block
      text-align center
      text-transform uppercase
      font-size 0.875rem
    &.IsSelected
      background-color $color_primary
      border-color $color_primary
      box-shadow $box_shadow_1
      span
        color #fff
</style>
