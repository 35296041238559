<template>
  <div class="ReuseOrPreventionSelect">
    <SectionHeadline :text="mixWB('REUSE_AND_PREVENTION')" />
    <div
      class="ItemWrap"
      v-for="(item, index) in items"
      v-show="item.isShowing"
      :key="item.id">
      <span class="Title">{{ item.title }}</span>
      <RadioButton
        :groupName="item.courseOfAction+index"
        :name="item.id"
        :text="mixWB('TO_BE_DEMOLISHED')"
        value="demolition"
        :selectedValue="item.courseOfAction"
        @radio-button-click="onChange(item, 'demolition')" />
      <RadioButton
        :groupName="item.courseOfAction+index"
        :name="item.id"
        :text="mixWB('NOT_TO_BE_DEMOLISHED')"
        value="prevention"
        :selectedValue="item.courseOfAction"
        @radio-button-click="onChange(item, 'prevention')" />
      <RadioButton
        :groupName="item.courseOfAction+index"
        v-if="item.canBeReused"
        :name="item.id"
        :text="mixWB('TO_BE_REUSED')"
        value="reuse"
        :selectedValue="item.courseOfAction"
        @radio-button-click="onChange(item, 'reuse')" />
    </div>
  </div>
</template>

<script>
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import RadioButton from '@/components/FormElements/RadioButton.vue'

export default {
  name: 'ReuseOrPreventionSelect',
  props: {
    typeData: {
      type: Object,
      required: true,
    },
    surfaceAutoTypes: {
      type: Array,
      required: true,
    },
  },

  computed: {
    items() {
      const items = []

      const typeList = [this.typeData, ...this.surfaceAutoTypes.filter((x) => x.answer === 'yes').map((x) => x.type)]
      typeList.forEach((type, index) => {
        const rType = type.getResourceType()
        const wdgID = type.getWDGID()

        const isBoth = wdgID !== 'WDG-2' && type.coating.coatingTypeIDs.length

        // Add main type
        items.push({
          id: `material-${ type.id }`,
          typeID: type.id,
          kindOfMaterial: isBoth ? 'both' : 'material',
          title: isBoth ? type.getTitle({ details: true }) : type.getTitle(),
          courseOfAction: type.courseOfAction,
          isShowing: index === 0 || items[items.length - 1]?.courseOfAction === 'prevention',
          canBeReused: !rType?.options?.isNeverAlone,
        })

        // Add main type coating
        if (!isBoth && type.coating.coatingTypeIDs.length) {
          items.push({
            id: `coating-${ type.id }`,
            typeID: type.id,
            kindOfMaterial: 'coating',
            title: this.mixWB('X_ON_Y', [
              type.getCoatingText(),
              type.getTitle({ details: false }),
            ]),
            courseOfAction: type.coating.courseOfAction,
            isShowing: items[items.length - 1]?.courseOfAction === 'prevention',
            canBeReused: false,
          })
        }
      })

      return items
    },
  },
  methods: {
    onChange(affectedItem, courseOfAction) {
      const current = this.items.reduce((prev, item) => {
        const test = {
          id: item.typeID,
          kindOfMaterial: item.kindOfMaterial,
          courseOfAction: item.courseOfAction,
        }

        // Current
        if (
          affectedItem.kindOfMaterial === item.kindOfMaterial
          && affectedItem.typeID === item.typeID
        ) {
          test.courseOfAction = courseOfAction
        }

        // After current
        // prev.length is used to avoid manipulation of current item
        if (prev.length && prev[prev.length - 1].courseOfAction !== 'prevention') {
          test.courseOfAction = 'demolition'
        }

        prev.push(test)

        return prev
      }, [])

      this.$emit('update', current)
    },
  },
  components: { SectionHeadline, RadioButton },

}
</script>

<style lang="stylus" scoped>
  .ReuseOrPreventionSelect
    padding-top 10px
    padding-bottom 10px

  .ItemWrap
    margin-bottom 20px
    .Title
      text-transform uppercase
      padding-bottom 5px
      font-size 0.75rem
</style>
