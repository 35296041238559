<template>
  <div class="UnderliningAutoTypeSelect">
    <SectionHeadline :text="`${ mixWB('IS_THERE_X', [mixWB('UNDERLINING')]) }`" />

    <!-- Types -->
    <div class="TypeList">
      <div
        class="Type"
        :class="{ IsSelected: noOptionSelected }"
        @click="onNoClick">
        <span>{{ mixWB('NO') }}</span>
      </div>
      <div
        class="Type"
        v-for="type in underliningTypes"
        :key="type.id"
        :class="{ IsSelected: type.id === selectedTypeID}"
        @click="onTypeClick(type.id)">
        <span>{{ mixWB(type.translation) }}</span>
      </div>
    </div>

    <!-- Images -->
    <div
      v-if="activeStepNo >= 1"
      class="Section">
      <ImageSelect
        groupName="underlining"
        :categoryID="screeningType.categoryID"
        :resetImagesCounter="resetImagesCounter"
        @image-list-update="onImageListUpdate" />
    </div>

    <!-- Questions -->
    <QuestionsSelect
      v-show="activeStepNo >= 2 && typeQuestions.length"
      :title="mixWB('QUESTIONS_ABOUT_THE_TYPE')"
      :questions="typeQuestions"
      :resetQuestionsCounter="resetQuestionsCounter"
      @questions-updated="onQuestionsUpdated"
      @questions-all-answered="onQuestionsAllAnswered" />

    <!-- Condition -->
    <ConditionSelect
      v-if="autoTypes.length"
      v-show="activeStepNo >= 3"
      :typeData="autoTypes[0].type"
      @update="onConditionUpdate" />

    <!-- Amount -->
    <AmountsSelect
      v-if="autoTypes.length"
      v-show="activeStepNo >= 3"
      :isActive="activeStepNo === 3"
      :screeningType="autoTypes[0].type"
      :amountToCopy="screeningType.amounts[0]"
      @update-on-load="onAmountsUpdateOnLoad"
      @update="onAmountsUpdate"
      @finished="onAmountsFinished" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import AutoTypeSelect from '@/globals/javascript/models/AutoTypeSelect'
import Type from '@/globals/javascript/models/Type'
import ImageSelect from '@/components/ScreeningTypeSections/ImageSelect.vue'
import QuestionsSelect from '@/components/ScreeningTypeSections/QuestionsSelect.vue'
import { findSamplesAndTags } from '@/globals/javascript/_util/find-samples-and-tags'
import AmountsSelect from '@/components/ScreeningTypeSections/AmountsSelect.vue'
import ConditionSelect from '@/components/ScreeningTypeSections/ConditionSelect.vue'

export default {
  name: 'UnderliningAutoTypeSelect',
  props: {
    screeningType: {
      type: Object,
      required: true,
    },
    resetUnderliningCounter: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      noOptionSelected: false,
      autoTypes: [],
      activeStepNo: 0,
      resetImagesCounter: 0,
      resetQuestionsCounter: 0,
      roadMap: [
        'types', // 0
        'images', // 1
        'questions', // 2
        'amount', // 3
      ],
    }
  },
  computed: {
    ...mapGetters([
      'typesAsObject',
    ]),
    activeStep() {
      return this.roadMap[this.activeStepNo]
    },
    underliningTypes() {
      const list = []

      list.push(this.typesAsObject['T-82']) // PU foam
      list.push(this.typesAsObject['T-88']) // Tar
      list.push(this.typesAsObject['T-87']) // Mortar

      return list
    },
    selectedTypeID() {
      if (!this.autoTypes.length) {
        return false
      }
      return this.autoTypes[0].resourceTypeID
    },
    selectedType() {
      if (!this.autoTypes.length) {
        return false
      }
      return this.typesAsObject[this.selectedTypeID]
    },
    typeQuestions() {
      if (!this.selectedType) {
        return []
      }
      return this.selectedType.getQuestionsToAsk()
    },
  },
  watch: {
    resetUnderliningCounter() {
      this.onReset()
    },
  },
  methods: {
    onNoClick() {
      if (this.noOptionSelected) {
        return
      }

      this.onReset()
      this.noOptionSelected = true

      // Emit
      this.$emit('underlining-finished', this.autoTypes)
    },
    onTypeClick(typeID) {
      if (this.selectedTypeID === typeID) {
        return
      }

      this.onReset()

      requestAnimationFrame(() => {
        // Create new auto type
        this.autoTypes.push(new AutoTypeSelect({
          resourceTypeID: typeID,
          answer: 'yes',
          show: true,
          side: 'inner',
          type: new Type({
            autoTypeBaseID: this.screeningType.id,
            categoryID: this.screeningType.categoryID,
            type: {
              isOtherType: true,
              typeID,
            },
            unitIDs: this.screeningType.unitIDs,
          }),
        }))

        this.onNextStep()
      })
    },
    onImageListUpdate(imageList) {
      this.activeStepNo = 1
      this.autoTypes[0].type.images = imageList

      if (!imageList.length) {
        return
      }

      this.onNextStep()
    },
    onQuestionsUpdated() {
      this.activeStepNo = 2
    },
    onQuestionsAllAnswered(answers) {
      this.autoTypes[0].type.questionAnswers = answers
      this.onNextStep()
    },
    onAmountsUpdateOnLoad(amounts) {
      const { type } = this.autoTypes[0]
      type.amounts = amounts
    },
    onAmountsUpdate() {
      this.activeStepNo = 3
      this.$emit('underlining-updated')
    },
    onAmountsFinished(amounts) {
      const { type } = this.autoTypes[0]
      type.amounts = amounts

      this.onNextStep()
    },
    onConditionUpdate(value) {
      const { type } = this.autoTypes[0]

      if (type.condition === value) {
        type.condition = ''
      }
      else {
        type.condition = value
      }
    },
    onNextStep() {
      const { type } = this.autoTypes[0]

      // Emit update
      this.$emit('underlining-updated')

      // Step - Images
      if (this.activeStepNo < 1) {
        this.activeStepNo = 1
        return
      }

      // Step - Questions
      if (this.activeStepNo < 2 && this.typeQuestions.length) {
        this.activeStepNo = 2
        if (
          this.typeQuestions.length !== Object.keys(type.questionAnswers).length
        ) {
          return
        }
      }

      // Step - Amount
      if (this.activeStepNo < 3) {
        this.activeStepNo = 3
        if (this.autoTypes[0]?.type?.amounts?.[0]?.data?.type) {
          // Do nothing
        }
        else {
          return
        }
      }

      this.activeStepNo = 4

      // Complete
      // - Find types and tags
      const {
        samples,
        tags,
      } = findSamplesAndTags({
        selectedOption: this.selectedType,
        answers: type.questionAnswers,
        questions: this.typeQuestions,
      })
      type.samples = samples
      type.tags = tags

      // Add underlining tag
      type.tags.tagIDs.push('TAG-10')

      // - Emit result
      this.$emit('underlining-finished', this.autoTypes)
    },
    onReset() {
      this.noOptionSelected = false
      this.activeStepNo = 0
      this.resetImagesCounter += 1
      this.resetQuestionsCounter += 1
      this.autoTypes = []
    },
  },
  components: {
    SectionHeadline,
    ImageSelect,
    QuestionsSelect,
    AmountsSelect,
    ConditionSelect,
  },

}
</script>

<style lang="stylus" scoped>
  .UnderliningAutoTypeSelect
    margin 0 -20px 0px
    padding 20px 20px 5px
    background-color $color_grey_lightest
    border-top 1px solid $color_grey_lighter
    +below($tablet)
      padding 20px 15px 5px
      margin 0 -15px 0px

  .TypeList
    display flex
    flex-wrap wrap
    margin 0px -5px 10px

  .Type
    display flex
    align-items center
    justify-content center
    min-width 70px
    min-height 40px
    padding 5px 10px
    margin 0px 5px 10px
    background-color $color_grey_lightest
    border 1px solid lighten($color_grey_lighter, 25%)
    box-shadow $box_shadow_1
    cursor pointer
    span
      display block
      text-align center
      text-transform uppercase
      font-size 0.875rem
    &.IsSelected
      background-color $color_primary
      border-color $color_primary
      box-shadow $box_shadow_1
      span
        color #fff
</style>
