<template>
  <div
    v-if="imageUploadFolder"
    class="ImageSelect">
    <ImageUploadForm
      :place="imagePlace"
      :groupName="groupName"
      :title="mixWB('PICTURES')"
      :basePath="getBasePath()"
      :requiredNumber="1"
      :resetImagesCounter="resetImagesCounter"
      makeSquared
      @image-list-update="onImageListUpdate" />

    <ErrorText
      v-if="showMissingError"
      :text="mixWB('MISSING_IMAGES')"
      withBackground />
  </div>
</template>

<script>
import ImageUploadForm from '@/components/Forms/ImageUploadForm.vue'
import ErrorText from '@/components/FormElements/ErrorText.vue'
import EventBus from '@/EventBus'
import { mapGetters } from 'vuex'

export default {
  name: 'ImageSelect',
  props: {
    categoryID: {
      type: String,
      required: true,
    },
    groupName: {
      type: String,
      required: true,
    },
    resetImagesCounter: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      showMissingError: false,
    }
  },
  computed: {
    ...mapGetters([
      'categoriesAsObject',
      'imageUploadFolder',
    ]),
    currentCategory() {
      return this.categoriesAsObject[this.$route.params.categoryID]
    },
    imagePlace() {
      if (!this.currentCategory) {
        return ''
      }

      return this.mixWB(this.currentCategory.translation)
    },
  },
  methods: {
    onMissingImages({ groupName }) {
      if (this.groupName === groupName) {
        this.showMissingError = true
      }
    },
    getBasePath() {
      return `${
        this.imageUploadFolder
      }Types/${
        this.categoryID
      }/`
    },
    onImageListUpdate({ imageList }) {
      this.showMissingError = false
      this.$emit('image-list-update', imageList)
    },
    onTypeImagesLoad({ images, groupName }) {
      if (this.groupName !== groupName) {
        return
      }

      if (!images.length) {
        return
      }

      EventBus.$emit('set-images-on-load', {
        imageList: images,
        groupName: this.groupName,
      })
    },
  },
  components: {
    ImageUploadForm,
    ErrorText,
  },
  created() {
    EventBus.$on('load-type-images', this.onTypeImagesLoad)
    EventBus.$on('missing-images', this.onMissingImages)
  },
  destroyed() {
    EventBus.$off('load-type-images', this.onTypeImagesLoad)
    EventBus.$off('missing-images', this.onMissingImages)
  },
}
</script>

<style lang="stylus" scoped>
  .ImageSelect
    padding-top 10px
    padding-bottom 10px
</style>
