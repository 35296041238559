<template>
  <div class="UnitSelect">
    <SectionHeadline :text="mixWB('SELECT_UNITS')" />

    <!-- Edit view -->
    <div
      class="EditView"
      v-show="isActive">
      <div
        class="GroupWrap"
        v-for="group in unitGroups"
        :key="group.id">
        <UnitSelectGroup
          :group="group"
          :selectedUnitIDs="selectedUnitIDs"
          :topLevel="group.topLevel"
          @unit-click="onUnitClick" />
      </div>
    </div>

    <!-- Done view -->
    <div
      v-show="!isActive"
      class="DoneView">
      <Button
        v-for="unit in selectedUnitsForType"
        :key="unit.id"
        :text="unit.getFullTitle()"
        :fullWidth="false"
        :hasShadow="false"
        color="grey"
        outlined
        @button-click="onDoneViewClick" />
      <Button
        :text="mixWB('CHANGE')"
        :fullWidth="false"
        @button-click="onDoneViewClick" />
    </div>

    <!-- Button -->
    <div
      class="ButtonWrap"
      v-if="isActive">
      <Button
        :text="mixWB('NEXT')"
        :isDisabled="!selectedUnitIDs.length"
        @button-click="onButtonClick" />
    </div>

    <Dialog
      :isShowing="showNotificationModal"
      :useComponent="NoficationModal"
      :easyClose="false"
      :componentProps="{
        texts: notificationTexts,
      }"
      @close="onNotificationClose" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import UnitSelectGroup from '@/components/ScreeningTypeSections/UnitSelectGroup.vue'
import Button from '@/components/Buttons/Button.vue'
import EventBus from '@/EventBus'
import Dialog from '@/components/Modals/Dialog.vue'
import NoficationModal from '@/components/Modals/NoficationModal.vue'

export default {
  name: 'UnitSelect',
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedUnitIDs: [],
      interConnectionsAreStillValid: true,

      // Notificatin modal
      showNotificationModal: false,
      NoficationModal,
      notificationTexts: [],
    }
  },
  computed: {
    ...mapGetters([
      'selectedUnits',
      'currentScreeningSelectedFilterUnitID',
    ]),
    unitGroups() {
      const buildings = this.selectedUnits.filter((x) => x.type === 'building')
      const floors = this.selectedUnits.filter((x) => x.type === 'floor')
      const apartments = this.selectedUnits.filter((x) => x.type === 'apartment')
      const items = []

      buildings.forEach((building) => {
        const item = {
          id: building.id,
          topLevel: 'building',
        }
        items.push(item)
      })

      floors.forEach((floor) => {
        if (items.find((x) => x.id === floor.data.buildingID)) {
          return
        }

        const item = {
          id: floor.id,
          topLevel: 'floor',
        }
        items.push(item)
      })

      apartments.forEach((apartment) => {
        if (
          items.find((x) => x.id === apartment.data.floorID)
          || items.find((x) => x.id === apartment.data.buildingID)
        ) {
          return
        }

        const item = {
          id: apartment.id,
          topLevel: 'apartment',
        }
        items.push(item)
      })

      return items
    },
    selectedUnitsForType() {
      return this.selectedUnits.filter((x) => this.selectedUnitIDs.includes(x.id))
    },
  },
  methods: {
    onUnitClick(unitID) {
      if (this.selectedUnitIDs.includes(unitID)) {
        this.onUnitRemove(unitID)
      }
      else {
        this.onUnitAdd(unitID)
      }
    },
    onUnitRemove(unitID) {
      if (this.selectedUnits.length === 1) {
        return
      }
      const newUnitIDs = this.selectedUnitIDs.filter((x) => x !== unitID)

      // Check that removing this unit will not make any inter connections invalid
      this.interConnectionsAreStillValid = true
      EventBus.$emit('check-inter-connections-on-units-change', newUnitIDs)
      if (!this.interConnectionsAreStillValid) {
        return
      }

      this.selectedUnitIDs = newUnitIDs
    },
    onUnitAdd(unitID) {
      // Add new unit
      this.selectedUnitIDs.push(unitID)
    },
    onDoneViewClick() {
      this.$emit('units-update')
    },
    onButtonClick() {
      this.$emit('units-next-click', this.selectedUnitIDs)
    },
    onNotificationClose() {
      this.showNotificationModal = false
      this.notificationTexts = []
    },
    onLoadNewType() {
      // Set unit as selected if only 1 unit to choose from
      if (this.selectedUnits.length === 1) {
        this.selectedUnitIDs.push(this.selectedUnits[0].id)
        this.onButtonClick()
      }

      // Set unit if a single unit is selected by filter
      else if (this.currentScreeningSelectedFilterUnitID) {
        this.selectedUnitIDs.push(this.currentScreeningSelectedFilterUnitID)
        this.onButtonClick()
      }
    },
    onTypeLoad({ unitIDs }) {
      this.selectedUnitIDs = unitIDs
    },
    onInterConnnectionUnitsCheckStatus(foundTypes) {
      if (!foundTypes.length) {
        return
      }

      this.interConnectionsAreStillValid = false
      this.notificationTexts.push(this.mixWB(
        'THE_UNIT_IS_USED_IN_CONNECTION_WITH_X',
        [foundTypes.map((type) => type.getTitle(true).toLowerCase()).join(', ')],
      ))
      this.showNotificationModal = true
    },
  },
  components: {
    SectionHeadline,
    UnitSelectGroup,
    Button,
    Dialog,
  },
  created() {
    EventBus.$on('load-new-type', this.onLoadNewType)
    EventBus.$on('load-type-edit', this.onTypeLoad)
    EventBus.$on('inter-connections-units-check-status', this.onInterConnnectionUnitsCheckStatus)
  },
  destroyed() {
    EventBus.$off('load-new-type', this.onLoadNewType)
    EventBus.$off('load-type-edit', this.onTypeLoad)
    EventBus.$off('inter-connections-units-check-status', this.onInterConnnectionUnitsCheckStatus)
  },
}
</script>

<style lang="stylus" scoped>
  .UnitSelect
    display block

  .EditView
    .GroupWrap
      border-bottom 1px solid $color_grey_lighter
      margin-bottom 10px
      &:last-child
        border-bottom none

  .DoneView
    display flex
    flex-wrap wrap
    >>> .ButtonWrap
      margin-left 2.5px
      margin-right 2.5px
</style>
