<template>
  <div class="RecyclingSelect">
    <SectionHeadline :text="mixWB('RESALE')" />

    <Checkbox
      name="highlight"
      :isSelected="isHighlighted"
      :text="mixWB('HIGHLIGHT_FOR_RESALE')"
      @checkbox-click="onHighlightUpdate" />
  </div>
</template>

<script>
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import Checkbox from '@/components/FormElements/Checkbox.vue'

export default {
  name: 'RecyclingSelect',
  props: {
    isHighlighted: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onHighlightUpdate() {
      this.$emit('recycle-highlight-update', !this.isHighlighted)
    },
  },
  components: {
    SectionHeadline,
    Checkbox,
  },

}
</script>

<style lang="stylus" scoped>
  .RecyclingSelect
    padding-top 10px
    padding-bottom 10px
</style>
