import store from '@/store'
import QuestionSelect from '@/globals/javascript/models/QuestionSelect'
import { mixWB } from '@/globals/javascript/_util/mixins'

export const getCustomTypeQuestions = () => {
  const {
    sampleTypesAsArray,
  } = store.getters

  const questions = []

  // Create question for each type of sample
  sampleTypesAsArray.forEach((sampleType) => {
    // Don't include dust samples
    if (sampleType.id === 'ST-7') {
      return
    }

    questions.push(new QuestionSelect({
      id: sampleType.id,
      title: mixWB('TEST_FOR_X', [mixWB(sampleType.translation)]),
      sampleIDs: [sampleType.id],
      tagIDs: [],
      assessmentIDs: [],
      alwaysShow: true,
    }))
  })

  return questions
}
