<template>
  <div class="QuestionsSelect">
    <!-- Title -->
    <span
      v-if="title"
      class="Title">{{ title }}</span>

    <!-- Questions -->
    <div
      class="Question"
      v-for="(question, index) in questionList"
      :key="question.id"
      v-show="question.showQuestion">
      <SectionHeadline :text="question.title" />
      <div class="OptionsWrap">
        <div
          :class="{ 'IsSelected': question.answer === 1 }"
          class="Option"
          @click="onOptionClick(1, question, index)">
          <span>{{ mixWB('YES') }}</span>
        </div>
        <div
          :class="{ 'IsSelected': question.answer === 0 }"
          class="Option"
          @click="onOptionClick(0, question, index)">
          <span>{{ mixWB('NO') }}</span>
        </div>
        <div
          :class="{ 'IsSelected': question.answer === 2 }"
          class="Option"
          @click="onOptionClick(2, question, index)">
          <span>{{ mixWB('DONT_KNOW') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/EventBus'
import SectionHeadline from '../FormElements/SectionHeadline.vue'

export default {
  name: 'QuestionsSelect',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    questions: {
      type: Array,
      required: true,
    },
    resetQuestionsCounter: {
      type: Number,
      required: true,
    },
    subQuestionId: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      answers: {},
    }
  },
  computed: {
    questionList() {
      let lastAnswer = null
      return [...this.questions].map((question, index) => {
        // Add answer
        question.answer = this.answers.hasOwnProperty(question.id)
          ? this.answers[question.id]
          : null

        // Check to show
        question.showQuestion = true

        if (index === 0) {
          lastAnswer = question.answer
          return question
        }

        if (lastAnswer > 0) {
          lastAnswer = question.answer
          return question
        }

        if (question.alwaysShow && lastAnswer !== null) {
          lastAnswer = question.answer
          return question
        }

        question.showQuestion = false
        lastAnswer = question.answer
        return question
      })
    },
  },
  watch: {
    resetQuestionsCounter() {
      this.onResetQuestions()
    },
  },
  methods: {
    onLoadType({ questionAnswers }) {
      this.$set(this, 'answers', questionAnswers)
    },
    onLoadSubQuestion({ id, answers }) {
      if (id !== this.subQuestionId) {
        return
      }

      this.$set(this, 'answers', answers)
    },
    onOptionClick(answer, question, index) {
      if (question.answer === answer) {
        return
      }

      this.$set(this.answers, question.id, answer)

      // Reset below questions
      let alwaysShowQuestionFound = false
      this.questionList.forEach((question, questionIndex) => {
        if (questionIndex <= index) {
          return
        }
        if (alwaysShowQuestionFound) {
          return
        }
        if (question.alwaysShow) {
          alwaysShowQuestionFound = true
          return
        }
        if (answer === 0) {
          this.$set(this.answers, question.id, 0)
          return
        }
        this.$delete(this.answers, question.id)
      })

      // Emit update
      if (Object.keys(this.answers).length !== this.questionList.length) {
        this.$emit('questions-updated')
        return
      }

      // Emit done
      this.$emit('questions-all-answered', this.answers)
    },
    onResetQuestions() {
      this.$set(this, 'answers', {})
    },
  },
  components: {
    SectionHeadline,
  },
  created() {
    EventBus.$on('load-type-edit', this.onLoadType)
    EventBus.$on('load-sub-question', this.onLoadSubQuestion)
  },
  destroyed() {
    EventBus.$off('load-type-edit', this.onLoadType)
    EventBus.$off('load-sub-question', this.onLoadSubQuestion)
  },
}
</script>

<style lang="stylus" scoped>
  .QuestionsSelect
    display block

  .Title
    text-transform uppercase
    margin-bottom 10px
    font-size 1.125rem

  .Question
    margin-bottom 10px

  .OptionsWrap
    display flex
    justify-content space-between

  .Option
    display flex
    align-items center
    justify-content center
    width calc(33.33% - 7px)
    height 40px
    margin-bottom 10px
    background-color $color_grey_lightest
    border 1px solid lighten($color_grey_lighter, 25%)
    box-shadow $box_shadow_1
    cursor pointer
    span
      display block
      text-align center
      text-transform uppercase
      font-size 0.875rem
    &.IsSelected
      background-color $color_primary
      border-color $color_primary
      box-shadow $box_shadow_1
      span
        color #fff
</style>
