export default class AutoTypeSelect {
  constructor({
    resourceTypeID,
    activeNo,
    show,
    answer,
    side,
    type,
  }) {
    this.resourceTypeID = resourceTypeID
    this.activeNo = activeNo ?? 0
    this.show = show ?? false
    this.answer = answer ?? ''
    this.side = side ?? ''
    this.type = type ?? {}
  }
}
