<template>
  <div class="ColorSelect">
    <SectionHeadline :text="`${ mixWB('COLORS') }`" />

    <div class="ColorList">
      <div
        class="ColorItem"
        :class="{ 'ColorRainbow': color.colorCode === 'rainbow' }"
        v-for="color in colorsAsArray"
        :key="color.id"
        :style="{ backgroundColor: `${ color.colorCode }` }"
        @click="onColorClick(color.id)">
        <span class="Label">{{ mixWB(color.translation) }}</span>
        <div
          v-if="selectedColorIDs.includes(color.id)"
          class="Selected">
          <CheckIcon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeadline from '@/components/FormElements/SectionHeadline'
import { mapGetters } from 'vuex'
import CheckIcon from '@/assets/svg/check.svg?inline'

export default {
  name: 'ColorSelect',
  props: {
    selectedColorIDs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'colors',
      'colorsAsArray',
    ]),
  },
  methods: {
    onColorClick(colorID) {
      const colorIDListCopy = JSON.parse(JSON.stringify(this.selectedColorIDs))

      const index = colorIDListCopy.findIndex((x) => x === colorID)
      if (index > -1) {
        colorIDListCopy.splice(index, 1)
      }
      else {
        colorIDListCopy.push(colorID)
      }

      this.$emit('color-update', colorIDListCopy)
    },
  },
  components: {
    SectionHeadline,
    CheckIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .ColorSelect
    margin-bottom 15px
  .ColorList
    display flex
    flex-wrap wrap
    margin-left -2.5px
    margin-right -2.5px
  .ColorItem
    position relative
    margin 0 2.5px 5px
    box(60px)
    border 1px solid $color_grey_lighter
    display flex
    justify-content center
    cursor pointer
    &.ColorRainbow
      background linear-gradient(135deg, rgba(255,165,0,1) 0%,rgba(255,165,0,1) 20%,
      rgba(255,255,0,1) 20%,rgba(255,255,0,1) 20%,rgba(255,255,0,1) 40%,rgba(0,128,0,1) 40%,
      rgba(0,128,0,1) 60%, 60%,rgba(0,0,255,1) 60%,rgba(0,0,255,1) 80%,
      rgba(255,0,0,1) 80%);
    .Label
      position absolute
      bottom 0
      width 100%
      padding 2px 0px
      background-color rgba(0, 0, 0, 0.5)
      text-align center
      font-size 0.75rem
      color #fff
      line-height 1
    .Selected
      circle(56%)
      background-color rgba(#fff, 0.75)
      padding 5px
      border 1px solid $color_grey_lighter
      margin-top 5px
      svg
        fill $color_primary
</style>
