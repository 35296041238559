var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"InterConnectionsSelect"},[_c('SectionHeadline',{attrs:{"text":_vm.mixWB('SITTING_TOGETHER_WITH_INSIDE_AND_OUT')}}),_vm._l((_vm.interConnectionGroups),function(group){return _c('InterConnectionRow',{key:group.id,attrs:{"interConnectionGroupID":group.groupID,"typeItems":group.typeItems,"isAmountRequired":group.options.isAmountRequired,"typeData":_vm.typeData,"autoTypes":_vm.autoTypes,"allInterConnections":_vm.allInterConnections,"allScreeningTypes":_vm.allScreeningTypes,"allInterConnectionGroupAmounts":_vm.allInterConnectionGroupAmounts},on:{"area-update":_vm.onAreaUpdate,"no-type-selected":_vm.onNoTypeSelected,"inter-connection-select":_vm.onInterConnectionSelect}})}),(!_vm.activeAutoTypes.length)?_c('InterConnectionRow',{attrs:{"typeData":_vm.typeData,"autoTypes":_vm.autoTypes,"allInterConnections":_vm.allInterConnections,"allScreeningTypes":_vm.allScreeningTypes,"allInterConnectionGroupAmounts":_vm.allInterConnectionGroupAmounts},on:{"inter-connection-select":_vm.onInterConnectionSelect}}):_vm._e(),(_vm.showExistsOnItsOwn)?_c('div',[_c('Checkbox',{attrs:{"name":"exists-on-its-own","text":_vm.mixWB(
        'IS_X_ALSO_FOUND_AS_ITS_OWN_TYPE_TEXT',
        [_vm.typeData.getTitle(true)]
      ),"isSelected":!!_vm.typeData.isOwnTypeAsWell},on:{"checkbox-click":_vm.onCheckBoxClick}})],1):_vm._e(),_c('Dialog',{attrs:{"isShowing":_vm.nextQuestion.showQuestion || false,"useComponent":_vm.QuestionModal,"easyClose":false,"componentProps":{
      title: _vm.nextQuestion.questionTitle,
      question: _vm.nextQuestion.question || '',
      questionID: _vm.nextQuestion.id,
      additionalData: _vm.nextQuestion.additionalData,
      isYesOutlined: true,
    }},on:{"answer":_vm.onQuestionAnswer,"close":_vm.onQuestionClose}}),_c('Dialog',{attrs:{"isShowing":_vm.showNotificationModal,"useComponent":_vm.NoficationModal,"easyClose":false,"componentProps":{
      texts: _vm.notificationTexts,
    }},on:{"close":_vm.onNotificationClose}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }