<template>
  <div
    ref="mainWrap"
    class="ScreeningsUpdateCategoryAddType">
    <div class="InnerPage">
      <!-- Close -->
      <CloseButton :closePath="getClosePath()" />

      <!-- Header -->
      <span class="CategoryTitle">{{ currentCategoryTitle }}</span>

      <!-- Body -->
      <div class="TypeSteps">
        <!-- Units -->
        <UnitSelect
          :isActive="activeStepNo === 0"
          @units-update="onUnitsUpdate"
          @units-next-click="onUnitNextsClick"/>

        <!-- Images -->
        <ImageSelect
          ref="ImageSelect"
          v-show="activeStepNo >= 1"
          groupName="main-type"
          :categoryID="typeData.categoryID || ''"
          @image-list-update="onImageListUpdate" />

        <!-- Types -->
        <CategoryTypeSelect
          v-show="activeStepNo >= 2"
          :categoryID="currentCategory.id"
          :data="typeData.type"
          :isDisabled="!isNewType"
          @update="onCategoryTypeSelectUpdate" />

        <!-- Questions -->
        <QuestionsSelect
          v-show="activeStepNo >= 3 && typeQuestions.length"
          :title="mixWB('QUESTIONS_ABOUT_THE_TYPE')"
          :questions="typeQuestions"
          :resetQuestionsCounter="resetQuestionsCounter"
          @questions-updated="onQuestionsUpdated"
          @questions-all-answered="onQuestionsAllAnswered" />

        <!-- Work description group select -->
        <WorkDescriptionGroupSelect
          v-show="activeStepNo >= 4 && typeData.type.customTypeName"
          :selectedID="typeData.type.customTypeWDGID"
          @group-click="onGroupClick" />

        <!-- Coating  -->
        <CoatingSelect
          v-show="activeStepNo >= 5 && this.showCoatingSelect"
          groupName="main-type"
          :resetCoatingCounter="resetCoatingCounter"
          :typeName="typeData.getTitle()"
          @coating-updated="onCoatingUpdated"
          @coating-finished="onCoatingFinished" />

        <!-- Amount -->
        <AmountsSelect
          v-show="activeStepNo >= 6"
          :isActive="activeStepNo === 6"
          :screeningType="typeData"
          @update="onAmountsUpdate"
          @finished="onAmountsFinished" />

        <!-- Auto type - underlining -->
        <UnderliningAutoTypeSelect
          v-show="activeStepNo >= 7 && this.showUnderliningSelect"
          :screeningType="typeData"
          :resetUnderliningCounter="resetUnderliningCounter"
          @underlining-updated="onUnderliningUpdated"
          @underlining-finished="onUnderliningFinished" />

        <!-- Auto type - surface -->
        <SurfaceAutoTypeSelect
          v-if="isNewType"
          v-show="activeStepNo >= 8 && showSurfaceSelect"
          :screeningType="typeData"
          :resourceType="this.selectedType"
          :resourceCategoryType="this.selectedCategoryType"
          :category="currentCategory"
          @update-show-status="onSurfaceAutoTypeShowStatusUpdate"
          @auto-type-update="onSurfaceAutoTypeUpdate"
          @auto-types-finished="onSurfaceAutoTypesFinished" />

        <!-- Inter connections -->
        <InterConnectionsSelect
          ref="InterConnectionsSelect"
          v-show="showSaveButton"
          :typeData="typeData"
          :autoTypes="showSaveButton ? allAutoTypes : []"
          :resourceType="selectedType"
          @data-update="onInterConnectionDataUpdate"
          @exists-on-its-own-update="onExistsOnItsOwnUpdate" />

        <!-- Recycling -->
        <RecyclingSelect
          v-show="showSaveButton && showRecycleSelect"
          :isHighlighted="typeData.recycling.isHighlighted"
          @recycle-highlight-update="onRecycleHighlightUpdate" />

        <!-- Comments -->
        <Comments
          ref="note"
          v-show="showSaveButton && addOptions.note.isSelected"
          @comments-update="onCommentsUpdate" />

        <!-- Places -->
        <PlacesSelect
          ref="rooms"
          v-show="
            showSaveButton
              && currentCategory.areaID !== 'AREA-1'
              && addOptions.rooms.isSelected
          "
          :typeData="typeData"
          @place-update="onPlaceUpdate" />

        <!-- Condition -->
        <ConditionSelect
          ref="condition"
          v-show="showSaveButton && addOptions.condition.isSelected"
          :typeData="typeData"
          @update="onConditionUpdate" />

        <!-- Reuse or stay -->
        <ReuseOrPreventionSelect
          ref="reuseOrPrevention"
          v-show="showSaveButton && addOptions.reuseOrPrevention.isSelected"
          :typeData="typeData"
          :surfaceAutoTypes="surfaceAutoTypes"
          @update="onReuseOrPreventionUpdate" />

        <!-- Add options -->
        <AddOptions
          ref="AddOptions"
          v-if="
            showSaveButton && Object.entries(addOptions).some(([key, value]) => !value.isSelected)
          "
          :addOptions="addOptions"
          @option-click="onAddOptionClick" />

        <!-- Next/Save button -->
        <div
          v-if="showSaveButton"
          class="ButtonWrap">
          <Button
            :text="mixWB('SAVE')"
            :isLoading="isLoading"
            @button-click="onSaveButtonClick" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CloseButton from '@/components/ScreeningItems/CloseButton.vue'
import ImageSelect from '@/components/ScreeningTypeSections/ImageSelect.vue'
import CategoryTypeSelect from '@/components/ScreeningTypeSections/CategoryTypeSelect.vue'
import QuestionsSelect from '@/components/ScreeningTypeSections/QuestionsSelect.vue'
import WorkDescriptionGroupSelect from '@/components/ScreeningTypeSections/WorkDescriptionGroupSelect.vue'
import CoatingSelect from '@/components/ScreeningTypeSections/CoatingSelect.vue'
import UnderliningAutoTypeSelect from '@/components/ScreeningTypeSections/UnderliningAutoTypeSelect.vue'
import SurfaceAutoTypeSelect from '@/components/ScreeningTypeSections/SurfaceAutoTypeSelect.vue'
import AmountsSelect from '@/components/ScreeningTypeSections/AmountsSelect.vue'
import PlacesSelect from '@/components/ScreeningTypeSections/PlacesSelect.vue'
import InterConnectionsSelect from '@/components/ScreeningTypeSections/InterConnections/InterConnectionsSelect.vue'
import Comments from '@/components/ScreeningTypeSections/Comments.vue'
import Button from '@/components/Buttons/Button.vue'
import EventBus from '@/EventBus'
import { getCustomTypeQuestions } from '@/globals/javascript/_util/custom-types'
import { scrollTo } from '@/globals/javascript/_util/util'
import UnitSelect from '@/components/ScreeningTypeSections/UnitSelect.vue'
import Type from '@/globals/javascript/models/Type'
import Sample from '@/globals/javascript/models/Sample'
import Unit from '@/globals/javascript/models/units/Unit'
import { findSamplesAndTags } from '@/globals/javascript/_util/find-samples-and-tags'
import QuestionSelect from '@/globals/javascript/models/QuestionSelect'
import RecyclingSelect from '@/components/ScreeningTypeSections/RecyclingSelect.vue'
import { isEqual } from 'lodash-es'
import ConditionSelect from '@/components/ScreeningTypeSections/ConditionSelect.vue'
import ReuseOrPreventionSelect from '@/components/ScreeningTypeSections/ReuseOrPreventionSelect.vue'
import AddOptions from '@/components/ScreeningTypeSections/AddOptions.vue'

export default {
  name: 'ScreeningsUpdateCategoryAddType',
  data() {
    return {
      activeStepNo: 0,
      lastActiveNo: 0,
      isLoading: false,
      typeData: {},
      underliningAutoTypes: [],
      surfaceAutoTypes: [],
      showSurfaceSelect: false,
      isScrolledAllowed: false,
      roadMap: [
        'units', // Step 0
        'images', // Step 1
        'type', // Step 2
        'questions', // Step 3
        'work-description-group', // Step 4
        'coating', // Step 5
        'amount', // Step 6
        'underlining-auto-types', // Step 7
        'surface-auto-types', // Step 8
        'finished', // Step 9
      ],
      hadAutoAssessmentsOnLoad: false,
      // Inter connection data
      addedInterConnections: [],
      removedInterConnectionIDs: [],
      addedInterConnectionGroupAmounts: [],
      removedInterConnectionGroupAmounts: [],
      missingInterConnectionGroupAmountIDs: [],
      typesExistsOnTheirOwnAnswers: {},
      existingInterConnectionGroupsToCopy: [],
      // Resets
      resetQuestionsCounter: 0,
      resetCoatingCounter: 0,
      resetUnderliningCounter: 0,
      // Add options
      addOptions: {
        note: {
          isSelected: false,
          isAvailable: true,
        },
        rooms: {
          isSelected: false,
          isAvailable: true,
        },
        condition: {
          isSelected: false,
          isAvailable: true,
        },
        reuseOrPrevention: {
          isSelected: false,
          isAvailable: true,
        },
      },
    }
  },
  computed: {
    ...mapGetters([
      'categoriesAsArray',
      'categoryTypesAsArray',
      'categoryTypesAsObject',
      'screeningTypes',
      'currentRoute',
      'currentCategory',
      'currentCategoryTitle',
      'screeningSamples',
      'nextAssessNumber',
      'typesAsObject',
      'questionsAsObject',
      'currentScreeningData',
      'screeningRelatedScreenings',
    ]),
    isNewType() {
      return this.currentRoute.name === 'ScreeningsUpdateCategoryAddType'
    },
    currentCategory() {
      return this.categoriesAsArray.find((x) => x.id === this.typeData.categoryID)
    },
    currentCategoryTypes() {
      return this.categoryTypesAsArray.filter((x) => x.categoryID === this.currentCategory.id)
    },
    selectedType() {
      if (this.typeData.type.customTypeName) {
        return false
      }
      if (this.typeData.type.typeID) {
        return this.typesAsObject[this.typeData.type.typeID]
      }
      if (this.typeData.type.categoryTypeID) {
        const categoryType = this.categoryTypesAsObject[this.typeData.type.categoryTypeID]
        return this.typesAsObject[categoryType.typeID]
      }
      return false
    },
    selectedCategoryType() {
      if (!this.typeData.type.categoryTypeID) {
        return false
      }
      return this.categoryTypesAsObject[this.typeData.type.categoryTypeID]
    },
    typeQuestions() {
      let questions = []
      // Custom type
      if (this.typeData.type.customTypeName) {
        questions = getCustomTypeQuestions()
      }

      // Category type or type
      if (this.selectedType) {
        questions = this.selectedType.getQuestionsToAsk()
      }

      // Check to add question for chimneys
      if (this.currentCategory.id === 'C-16') {
        questions.push(QuestionSelect.getChimneySootQuestion())
      }

      return questions
    },
    allAutoTypes() {
      return this.underliningAutoTypes.concat(this.surfaceAutoTypes)
    },
    showCoatingSelect() {
      if (this.selectedCategoryType?.options?.askForCoating) {
        return true
      }
      if (this.typeData.type.isOtherType) {
        return true
      }

      return false
    },
    showUnderliningSelect() {
      if (!this.isNewType) {
        return false
      }
      if (this.selectedCategoryType?.options?.askForUnderlining) {
        return true
      }
      if (this.selectedType?.options?.isNeverAlone) {
        return false
      }
      if (this.currentCategory.id === 'C-1' && this.typeData.type.isOtherType) {
        return true
      }
      return false
    },
    showRecycleSelect() {
      if (this.selectedType?.options?.isNeverAlone) {
        return false
      }
      if (this.selectedType?.options?.isNotRecyclable) {
        return false
      }
      return true
    },
    activeStep() {
      return this.roadMap[this.activeStepNo]
    },
    showSaveButton() {
      if (this.activeStep === 'finished') {
        return true
      }

      return false
    },
  },
  methods: {
    getClosePath() {
      return { name: 'ScreeningsUpdateCategory' }
    },
    onUnitsUpdate() {
      this.saveLastActiveStepNo(0)
    },
    onUnitNextsClick(selectedUnitIDs) {
      this.typeData.unitIDs = selectedUnitIDs
      this.onNextStep()
    },
    onImageListUpdate(imageList) {
      this.typeData.images = imageList

      if (this.activeStepNo < 2 && imageList.length) {
        this.onNextStep()
      }
    },
    onCategoryTypeSelectUpdate({ data, isDone }) {
      if (!this.isNewType) {
        return
      }

      this.typeData.type = data
      this.activeStepNo = 2
      this.lastActiveNo = 2

      if (!isDone) {
        return
      }

      this.resetQuestions()
      this.resetCoating()
      this.resetUnderlining()
      this.resetSurface()
      this.resetAmounts()
      this.resetCourseOfAction()

      requestAnimationFrame(() => {
        this.onNextStep()
      })
    },
    onQuestionsUpdated() {
      this.saveLastActiveStepNo(3)
      this.scrollToLastSection()
    },
    onQuestionsAllAnswered(answers) {
      this.saveLastActiveStepNo(3)
      this.$set(this.typeData, 'questionAnswers', answers)
      this.onNextStep()
    },
    onGroupClick(wdgID) {
      this.typeData.type.customTypeWDGID = wdgID
      this.saveLastActiveStepNo(4)
      this.onNextStep()
    },
    onCoatingUpdated() {
      this.saveLastActiveStepNo(5)
      this.scrollToLastSection()
    },
    onCoatingFinished(coatingData) {
      this.$set(this.typeData, 'coating', coatingData)
      this.saveLastActiveStepNo(5)
      this.onNextStep()
    },
    onAmountsUpdate() {
      this.saveLastActiveStepNo(6)
    },
    onAmountsFinished(amounts) {
      this.typeData.amounts = amounts
      this.onNextStep()
    },
    onUnderliningUpdated() {
      this.saveLastActiveStepNo(7)
    },
    onUnderliningFinished(autoTypes) {
      this.underliningAutoTypes = autoTypes
      this.saveLastActiveStepNo(7)
      this.onNextStep()
    },
    onSurfaceAutoTypeShowStatusUpdate(status) {
      this.showSurfaceSelect = status
    },
    onSurfaceAutoTypeUpdate() {
      this.saveLastActiveStepNo(8)
    },
    onSurfaceAutoTypesFinished(surfaceAutoTypes) {
      this.surfaceAutoTypes = surfaceAutoTypes
      this.saveLastActiveStepNo(8)
      this.onNextStep()
    },
    onInterConnectionDataUpdate({ name, value }) {
      this[name] = value
    },
    onExistsOnItsOwnUpdate() {
      this.typeData.isOwnTypeAsWell = !this.typeData.isOwnTypeAsWell
    },
    onPlaceUpdate(value) {
      this.typeData.places = value
    },
    onConditionUpdate(value) {
      if (this.typeData.condition === value) {
        this.typeData.condition = ''
      }
      else {
        this.typeData.condition = value
      }
    },
    onReuseOrPreventionUpdate(list) {
      const typeList = [this.typeData, ...this.surfaceAutoTypes.filter((x) => x.answer === 'yes').map((x) => x.type)]

      list.forEach((item) => {
        const type = typeList.find((x) => x.id === item.id)

        if (['material', 'both'].includes(item.kindOfMaterial)) {
          type.courseOfAction = item.courseOfAction
        }
        if (['coating', 'both'].includes(item.kindOfMaterial)) {
          type.coating.courseOfAction = item.courseOfAction
        }
      })

      // Auto set for underlining
      const underliningType = this.underliningAutoTypes[0]?.type
      if (underliningType) {
        if (this.typeData.courseOfAction === 'reuse') {
          underliningType.courseOfAction = 'demolition'
        }
        else {
          underliningType.courseOfAction = this.typeData.courseOfAction
        }
      }
    },
    onCommentsUpdate(value) {
      this.typeData.comments = value
    },
    onRecycleHighlightUpdate(value) {
      this.typeData.recycling.isHighlighted = value
    },
    onAddOptionClick(optionID) {
      this.addOptions[optionID].isSelected = true

      // Scroll into view
      requestAnimationFrame(() => {
        scrollTo({
          element: this.$refs[optionID].$el,
          offset: -50,
          checkBetween: true,
        })
      })
    },
    saveLastActiveStepNo(editedStepNumber) {
      if (this.activeStepNo > editedStepNumber) {
        this.lastActiveNo = this.activeStepNo
      }
      this.activeStepNo = editedStepNumber
    },
    onNextStep() {
      if (this.isLoading) {
        return
      }

      this.scrollToLastSection()

      if (this.lastActiveNo > this.activeStepNo) {
        this.setActiveStepNo(this.lastActiveNo)
        return
      }

      this.lastActiveNo = 0

      // Step - Images
      if (this.activeStepNo < 1) {
        this.setActiveStepNo(1)
        return
      }

      // Step - Type
      if (this.activeStepNo < 2) {
        this.setActiveStepNo(2)
        return
      }

      // Step - Questions
      if (this.activeStepNo < 3 && this.typeQuestions.length) {
        this.setActiveStepNo(3)
        return
      }

      // Step - Work description group
      if (this.activeStepNo < 4 && this.typeData.type.customTypeName) {
        this.setActiveStepNo(4)
        return
      }

      // Step - Coating
      if (this.activeStepNo < 5 && this.showCoatingSelect) {
        this.setActiveStepNo(5)
        return
      }

      // Step - Amount
      if (this.activeStepNo < 6) {
        this.setActiveStepNo(6)
        return
      }

      // Step - Underlining auto types
      if (this.activeStepNo < 7 && this.showUnderliningSelect) {
        this.setActiveStepNo(7)
        return
      }

      // Step - Surface auto types
      if (this.activeStepNo < 8 && this.showSurfaceSelect) {
        this.setActiveStepNo(8)
        return
      }

      this.setActiveStepNo(9)
    },
    setActiveStepNo(number) {
      this.findSamplesAndTags()
      this.activeStepNo = number
    },
    resetQuestions() {
      this.typeData.questionAnswers = {}
      this.resetQuestionsCounter += 1
    },
    resetCoating() {
      this.typeData.resetCoating()
      this.resetCoatingCounter += 1
    },
    resetUnderlining() {
      this.underliningAutoTypes = []
      this.resetUnderliningCounter += 1
    },
    resetSurface() {
      this.surfaceAutoTypes = []
      EventBus.$emit('auto-type-reset')
    },
    resetAmounts() {
      requestAnimationFrame(() => {
        this.typeData.amounts = []
        EventBus.$emit('amounts-reset')
      })
    },
    resetCourseOfAction() {
      this.typeData.courseOfAction = 'demolition'
      this.typeData.coating.courseOfAction = 'demolition'
    },
    findSamplesAndTags() {
      const {
        samples,
        tags,
        assessments,
      } = findSamplesAndTags({
        selectedOption: this.selectedType,
        answers: this.typeData.questionAnswers,
        questions: this.typeQuestions,
      })

      this.typeData.samples.sampleIDs = samples.sampleIDs
      this.typeData.samples.items = samples.items
      this.typeData.tags = tags
      this.typeData.assessments = assessments
    },
    onSaveButtonClick() {
      this.saveType()
    },
    saveType() {
      if (this.isLoading) {
        return
      }

      // Check for images
      if (!this.typeData.images.length) {
        scrollTo({
          element: this.$refs.ImageSelect.$el,
          offset: -50,
        })
        EventBus.$emit('missing-images', { groupName: 'main-type' })
        return
      }

      // Validate inter connections
      let allInterConnectionsGood = true
      this.addedInterConnections.forEach((interConnection) => {
        const isSplitStatusOK = !!interConnection.splitStatus
        const isEffectDirectionOK = interConnection.splitStatus === 'stuck'
          || (interConnection.splitStatus === 'splitable' && interConnection.effectDirection)

        if (!isSplitStatusOK || !isEffectDirectionOK) {
          EventBus.$emit('invalid-inter-connection', {
            isSplitStatusOK,
            isEffectDirectionOK,
            groupID: interConnection.groupID,
            scrollToHere: allInterConnectionsGood,
          })
          allInterConnectionsGood = false
        }
      })
      if (!allInterConnectionsGood) {
        return
      }

      // Validate inter connnection group amounts
      if (
        !this.currentScreeningData.isSkipAmount
        && this.missingInterConnectionGroupAmountIDs.length
      ) {
        EventBus.$emit(
          'missing-requried-amount-inter-connection',
          this.missingInterConnectionGroupAmountIDs[0],
        )
        return
      }

      this.isLoading = true

      this.findSamplesAndTags()
      this.setExistsOnItsOwnState()

      // Check for equivalent samples
      this.checkForEquivalentSamples()

      // Save type
      this.$store.dispatch('setScreeningType', {
        type: this.typeData,
        isNew: this.isNewType,
      })

      // Handle auto types
      this.$store.dispatch('handleAutoTypes', {
        mainType: this.typeData,
        autoTypes: JSON.parse(JSON.stringify(this.allAutoTypes)),
      })

      // Update exist on their own status for connected types
      this.updateExistOnTheirOwnForConnectedTypes()

      // Update inter connections
      this.updateInterConnections()

      // Check to remove auto assessed sample
      this.checkToRemoveAutoAssessedSample()

      // Ensure all sample markings has correct units, if unit have changed they should be reset.
      this.updateSampleMarkings()

      this.$router.push({
        name: 'ScreeningsUpdateCategory',
        params: {
          categoryID: this.$route.query.from || this.currentCategory.id,
        },
      })
    },
    setExistsOnItsOwnState() {
      if (this.addedInterConnections.length && this.typeData.isOwnTypeAsWell === null) {
        this.typeData.isOwnTypeAsWell = false
      }
    },
    updateExistOnTheirOwnForConnectedTypes() {
      Object.keys(this.typesExistsOnTheirOwnAnswers).forEach((typeID) => {
        const type = this.screeningTypes.find((x) => x.id === typeID)
        if (!type) {
          return
        }
        type.isOwnTypeAsWell = this.typesExistsOnTheirOwnAnswers[typeID]
        this.$store.dispatch('setScreeningType', { type })
      })
    },
    updateInterConnections() {
      // Remove inter connections
      this.removedInterConnectionIDs.forEach((interConnectionID) => {
        this.$store.dispatch('deleteInterConnection', interConnectionID)
      })

      // Add or update inter connections
      this.addedInterConnections.forEach((interConnection) => {
        this.$store.dispatch('setScreeningInterConnection', interConnection)
      })

      // Create copies of existing groups
      this.existingInterConnectionGroupsToCopy.forEach((group) => {
        group.interConnections.forEach((interConnection) => {
          this.$store.dispatch('setScreeningInterConnection', interConnection)
        })
      })

      // Remove inter connection group amounts
      this.removedInterConnectionGroupAmounts.forEach((groupAmount) => {
        this.$store.dispatch('deleteScreeningInterConnectionGroupAmount', groupAmount)
      })

      // Add or update inter connection group amounts
      if (this.addedInterConnectionGroupAmounts.length) {
        this.$store.dispatch(
          'setScreeningInterConnectionGroupAmount',
          this.addedInterConnectionGroupAmounts,
        )
      }
    },
    checkToRemoveAutoAssessedSample() {
      if (!this.hadAutoAssessmentsOnLoad && !this.typeData.assessments.sampleIDs.length) {
        return
      }

      // Find existing sample
      const existingMaterialSample = this.screeningSamples.find(
        (x) => x.typeID === this.typeData.id && x.kindOfSample === 'material',
      )

      // Conclude item is already there
      if (existingMaterialSample && this.typeData.assessments.sampleIDs.length) {
        return
      }

      // Remove unneeded sample
      if (existingMaterialSample && !this.typeData.assessments.sampleIDs.length) {
        this.$store.dispatch('deleteScreeningSample', existingMaterialSample)
      }

      // Add new sample
      if (!existingMaterialSample && this.typeData.assessments.sampleIDs.length) {
        const sample = new Sample({
          typeID: this.typeData.id,
          kindOfSample: 'material',
          isAutoAssessment: true,
          status: 'auto-assess-asbestos-perforated-ceiling-tiles',
          assessNumber: this.nextAssessNumber,
        })

        this.$store.dispatch('setScreeningSample', { sample, isNew: true })
      }
    },
    checkForEquivalentSamples() {
      if (this.isNewType) {
        return
      }

      /**
       * Material
       */
      const materialSample = this.screeningSamples.find(
        (x) => x.typeID === this.typeData.id && x.kindOfSample === 'material',
      )

      // When material has a standard sample
      if (materialSample?.sampleNumber) {
        const currentMaterialSampleIDs = this.typeData.getMaterialSampleList({ onlyIDs: true })
          .sort()

        // Check to remove sample for this type
        if (!currentMaterialSampleIDs.length) {
          this.$store.dispatch('deleteScreeningSample', materialSample)
        }

        // Find equivalent samples
        else {
          const equivalentSamples = this.screeningSamples.filter(
            (x) => !x.otherScreeningID && x.equivalentSampleNumber === materialSample.sampleNumber,
          )

          // Check to unconnect equivalent samples
          equivalentSamples.forEach((equivalentSample) => {
            const equivalentMaterialSampleIDs = equivalentSample.getSampleIDs(
              { onlyIDs: true },
            ).sort()

            if (!isEqual(currentMaterialSampleIDs, equivalentMaterialSampleIDs)) {
              this.$store.dispatch('deleteScreeningSample', equivalentSample)
            }
          })
        }
      }

      // When material has an equivalent sample
      if (materialSample?.equivalentNumber) {
        const currentMaterialSampleIDs = this.typeData.getMaterialSampleList({ onlyIDs: true })
          .sort()

        let standardSample = null
        // When pointing to sample in other project
        if (materialSample.otherScreeningID) {
          const project = this.screeningRelatedScreenings.find(
            (x) => x.data.id === materialSample.otherScreeningID,
          )

          standardSample = project?.samples?.find(
            (x) => x.sampleNumber === materialSample.equivalentSampleNumber,
          )
        }
        else {
          standardSample = this.screeningSamples.find(
            (x) => !x.otherScreeningID && x.sampleNumber === materialSample.equivalentSampleNumber,
          )
        }

        const standardSampleSampleIDs = standardSample?.getSampleIDs(
          { onlyIDs: true },
        ).sort()

        // Check to remove equivalent material sample for this type
        if (!isEqual(currentMaterialSampleIDs, standardSampleSampleIDs)) {
          this.$store.dispatch('deleteScreeningSample', materialSample)
        }
      }

      /**
       * Coating
       */
      const coatingSample = this.screeningSamples.find(
        (x) => x.typeID === this.typeData.id && x.kindOfSample === 'coating',
      )

      // When coating has a standard sample
      if (coatingSample?.sampleNumber) {
        const currentCoatingSampleIDs = this.typeData.getCoatingSampleList({ onlyIDs: true })
          .sort()

        // Check to remove sample for this type
        if (!currentCoatingSampleIDs.length) {
          this.$store.dispatch('deleteScreeningSample', coatingSample)
        }

        // Find equivalent samples
        else {
          const equivalentSamples = this.screeningSamples.filter(
            (x) => !x.otherScreeningID && x.equivalentSampleNumber === coatingSample.sampleNumber,
          )

          // Check to unconnect equivalent samples
          equivalentSamples.forEach((equivalentSample) => {
            const equivalentCoatingSampleIDs = equivalentSample.getSampleIDs(
              { onlyIDs: true },
            ).sort()

            if (!isEqual(currentCoatingSampleIDs, equivalentCoatingSampleIDs)) {
              this.$store.dispatch('deleteScreeningSample', equivalentSample)
            }
          })
        }
      }

      // When coating has an equivalent sample
      if (coatingSample?.equivalentNumber) {
        const currentCoatingSampleIDs = this.typeData.getCoatingSampleList({ onlyIDs: true })
          .sort()

        let standardSample = null
        // When pointing to sample in other project
        if (coatingSample.otherScreeningID) {
          const project = this.screeningRelatedScreenings.find(
            (x) => x.data.id === coatingSample.otherScreeningID,
          )

          standardSample = project?.samples?.find(
            (x) => x.sampleNumber === coatingSample.equivalentSampleNumber,
          )
        }
        else {
          standardSample = this.screeningSamples.find(
            (x) => !x.otherScreeningID && x.sampleNumber === coatingSample.equivalentSampleNumber,
          )
        }

        const standardSampleSampleIDs = standardSample?.getSampleIDs(
          { onlyIDs: true },
        ).sort()

        // Check to remove equivalent material sample for this type
        if (!isEqual(currentCoatingSampleIDs, standardSampleSampleIDs)) {
          this.$store.dispatch('deleteScreeningSample', coatingSample)
        }
      }
    },
    updateSampleMarkings() {
      const { unitIDs } = this.typeData

      this.screeningSamples.forEach((sample) => {
        const sampleFloorPlanUnitID = sample.floorPlanUnit

        if (sample.typeID !== this.typeData.id
          || !sampleFloorPlanUnitID
          || unitIDs.includes(sampleFloorPlanUnitID)) {
          return
        }

        // Check if the floorplanUnit is on any parent of this types units.
        const hasParentUnitWithSampleFloorPlan = Unit.getUnitsByIds(unitIDs).some(
          (unit) => unit.parentsWithFloorPlan().includes(sampleFloorPlanUnitID),
        )
        if (hasParentUnitWithSampleFloorPlan) {
          return
        }

        // The sample is marked on a unit that does not lie on this type anymore. Edge case where
        // a unit which the sample was marked on, was removed.
        sample.resetFloorPlan()
        sample.status = 'not-done'

        this.$store.dispatch('setScreeningSample', { sample })
      })
    },
    scrollToLastSection() {
      if (!this.isScrolledAllowed) {
        return
      }

      requestAnimationFrame(() => {
        scrollTo({
          element: this.$refs.mainWrap,
          offset: 100,
          goToBottom: true,
        })
      })
    },
    setNewTypeOnLoad() {
      const type = new Type({
        categoryID: this.$route.params.categoryID,
      })

      this.$set(this, 'typeData', type)
      this.isScrolledAllowed = true

      requestAnimationFrame(() => {
        EventBus.$emit('load-new-type')
      })

      // Update add options
      this.addOptions.rooms.isAvailable = this.currentCategory.areaID !== 'AREA-1'
    },
    setEditTypeOnLoad() {
      const savedType = this.screeningTypes.find(
        (x) => x.id === this.$route.params.addedTypeID,
      )

      this.activeStepNo = this.roadMap.length - 1

      const type = new Type({
        ...JSON.parse(JSON.stringify(savedType)),
      })

      this.typeData = type

      // Auto assessments
      this.hadAutoAssessmentsOnLoad = !!this.typeData.assessments.sampleIDs.length

      // Send data to sections
      requestAnimationFrame(() => {
        EventBus.$emit('load-type-edit', this.typeData)
        EventBus.$emit('load-type-images', { images: this.typeData.images, groupName: 'main-type' })
        EventBus.$emit('load-type-coating', { coating: this.typeData.coating, groupName: 'main-type' })
      })

      // Update addOptions
      this.addOptions.rooms.isAvailable = this.currentCategory.areaID !== 'AREA-1'
      if (this.typeData.comments) {
        this.addOptions.note.isSelected = true
      }
      if (this.typeData.places.placeIDs.length || this.typeData.places.otherPlaces.length) {
        this.addOptions.rooms.isSelected = true
      }
      if (this.typeData.condition) {
        this.addOptions.condition.isSelected = true
      }
      if (this.typeData.courseOfAction !== 'demolition') {
        this.addOptions.reuseOrPrevention.isSelected = true
      }
    },
  },
  components: {
    CloseButton,
    Button,
    UnitSelect,
    ImageSelect,
    CategoryTypeSelect,
    QuestionsSelect,
    WorkDescriptionGroupSelect,
    CoatingSelect,
    UnderliningAutoTypeSelect,
    SurfaceAutoTypeSelect,
    AmountsSelect,
    PlacesSelect,
    InterConnectionsSelect,
    Comments,
    RecyclingSelect,
    ConditionSelect,
    ReuseOrPreventionSelect,
    AddOptions,
  },
  created() {
    if (this.isNewType) {
      this.setNewTypeOnLoad()
    }
    else {
      this.setEditTypeOnLoad()
    }
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningsUpdateCategoryAddType
    page-wrap-outer-1()

  .InnerPage
    page-wrap-inner-1()

  .CategoryTitle
    display block
    font-size 1.125rem
    font-weight bold
    text-transform uppercase
    margin-bottom 20px

  .ButtonWrap
    margin-top 20px
</style>
